p {
  font-size: 1rem;
  line-height: 1.4;
}
small,
.small {
  font-size: 75%;
}

// Type display classes
.display-1 {
  font-size: 1.6rem;
  font-weight: 700;
}
.display-2 {
  font-size: 1.2rem;
}
.display-3 {
  font-size: 1.2rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60vw;
}
.display-4 {
  font-size: 1rem;
}

@media (min-width: 767.98px) {
  .display-2 {
    font-size: 1.6rem;
    margin-top: 5px;
  }

  .display-1 {
    font-size: 2rem;
  }
  .display-3 {
    font-size: 2rem;
    max-width: 18vw;
  }
  .display-4 {
    font-size: 1.4rem;
  }
}
