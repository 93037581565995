.timer {
  display: flex;
  gap: 0.5rem;
  &>div{
    min-width: 29px;
  }
  &-static{
    span{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: #FFE200;
      width: 17px;
      height: 17px;
      font-size: 1rem;
       margin-right: 3px;
    }
    h4{
font-size: 12px;
@media (max-width:374px) {
  font-size: 3vw;
}      

    }
    p{
      font-size: 8px;
    }
  }
&-wrapper{
  min-width: 15vw;
  min-height: 15vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $grey-2;
  border-radius: 1rem;
  span{
    padding: 4px 0;
    border-top: 2px solid $primary;
  }
}
&-container{
 gap: 10px;
  text-align: center;

}
  strong,
  small {
    color: $dark;
    text-align: center;
  }
.points{
background-color: transparent;
color: $warning;
padding: 20px 3px;
margin: 0;
}
  strong {
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 700;
  background-color: $warning;
    padding: 0.6rem;

  }

  small {
    font-size: 0.8rem;
    line-height: 1.4;
    display: block;
   color: $light;
    margin-top: 0.5rem;
  }

  &-block {
  
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $light;
      text-align: left;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  
  .timer {
    gap: 1.3rem;
    strong {
      font-size: 3.9rem;
    }
    &-container{
      max-width: 680px;
    
      
    }
    &-static{
      h4{
        font-size: 2.7rem;
        margin-right: 40px;
      }
      strong{
        font-size: 2rem;
        padding-top: 4px;
      }
      span{
        display: inline-block;
          width: 37px;
        height: 37px;
        font-size: 2rem;
        padding-top: 7px;
        text-align: center;
        margin-right: 3px;
      }
      p{
        font-size: 1.6rem;
      }
    }
    &-wrapper{
      min-width: 95px;
      min-height: 95px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: $grey-2;
      border-radius: 1rem;
      span{
        padding-top: 4px;
        border-top: 2px solid $primary;
      }
    }
   
    small{
      font-size: 2.6rem;
      margin-top: 7px;
    }
    &>div{
      min-width: 94px;
    }
  }
}


.toggle-button-show {
 position: fixed;
 background: $dark;
padding: 10px 12px;
  border-radius: 20px 0 0 20px;
  top: 60px;
  border: none;
  z-index: 1000;
  &:focus-visible{
    outline: 0;
  }
  img{
    width: 22px;
  }
}
.show-btn{
  display: flex;
  justify-content: flex-end;

}


.time-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.points {
  margin: 0 5px;
}
