.capture-image {
  display: block;
  position: absolute;
  transform: translate(0%, 0%);
}

.title-show-popup {
  align-items: center;

  .title {
    font-size: 1.2rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .title-icon {
    width: 15px;
  }
}
.overflow-hidden-n {
  .modal-header {
    .close {
      position: relative;
      z-index: 9;
      right: 10px;
      top: 2px;
    }
  }
}

.modal {
  padding-left: 0;
  &-profile-video {
    background-size: contain;
    .text-post {
      min-height: 82vw;
      max-height: 82vw;
      p {
        padding: 0 13px 0 101px;
        height: 82vw;
      }
      @media (min-width: 360px) {
        min-height: 340px;
        max-height: 340px;
        p {
          padding: 0 13px 0 132px;
          height: 340px;
        }
      }
    }
    // .text-post {
    //   p {
    //     padding: 0 13px 0 51px;
    //     height: 276px;
    //   }
    // }
  }
  &-heading-title {
    font-size: 2rem;
  }
  &-code {
    margin: 0 -10px;
  }
  &-subcontent {
    font-size: 1.6rem;
  }
  &-reward-code {
    .close {
      position: relative;
      z-index: 99;
      right: 10px;
    }
  }
  &-voucher-code {
    .card-img-top {
      margin-top: -3.5rem;
    }
    .para {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  &-md {
    max-width: 690px;
  }

  &-image {
    height: 150px;
  }

  &-sm {
    max-width: 412px;
  }

  &-body {
    .footer {
      &-claim-form {
        bottom: unset;
        width: unset;
        position: relative;
      }
    }
  }

  &-animation {
    position: absolute;
  }

  &-nojoke {
    height: 194px;
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      font-size: 2.2rem;
    }
  }

  &-onboard {
    margin: 4.5rem auto 0 auto !important;

    video {
      max-height: 345px;
    }
  }

  &-close {
    width: 18px;
    top: -6px;
    z-index: 2;
    position: relative;
  }

  &-filter {
    .modal {
      &-body {
        h3.modal-title {
          margin-top: -20px;
        }
      }
    }
  }

  &-contact {
    font-size: 1.4rem;
    font-weight: 700;
  }

  &-otp {
    .modal {
      &-body {
        padding: 6rem 1rem 3rem 1rem;

        .bottle {
          top: -16%;
        }
      }
    }
  }

  &-header {
    .title {
      font-size: 1.4rem;
    }

    .subtitle {
      font-size: 1rem;
    }

    .title-icon {
      // width: 17px;
    }
  }

  .footer {
    &-reaction {
      .icon {
        img {
          width: 22px !important;
          height: 22px !important;
        }

        &-num {
          font-size: 1.2rem;
        }
      }
    }
  }

  &-dialog {
    margin: 0 auto;
    padding-left: 0 !important;
    max-width: 400px;
  }

  &-spacing {
    padding: 0vw 3vw;
  }

  &-surprise {
    .container {
      padding: 0;
    }
    .text-post {
      min-height: 82vw;
      max-height: 82vw;
      p {
        padding: 0 13px 0 101px;
        height: 82vw;
      }
      @media (min-width: 360px) {
        min-height: 340px;
        max-height: 340px;
        p {
          padding: 0 13px 0 132px;
          height: 340px;
        }
      }
    }

    .logo {
      margin-top: -198px;
      transform: rotate(-1.771deg);
      width: 134px;
    }

    .icon-type {
      position: absolute;
      right: 28px;
      top: 14px;
      width: 40px;
    }

    .lock {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-48%, -83%);
    }

    .vote {
      display: none;
    }

    .card {
      &-joke {
        margin-bottom: 0 !important;
        padding: 0;

        .card-footer {
          border: 1px solid $grey;
          padding: 18px 0;
          border-radius: 0px 0px 5px 5px;
          background: #fff;

          box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.05),
            0px -4px 4px 0px rgba(0, 0, 0, 0.04),
            0px -10px 6px 0px rgba(0, 0, 0, 0.03),
            0px -17px 7px 0px rgba(0, 0, 0, 0.01),
            0px -27px 8px 0px rgba(0, 0, 0, 0);

          .icon-block {
            bottom: 4px !important;
          }
        }
      }

      &-report {
        display: none;
      }

      .icon {
        font-size: 1rem;
      }
    }
  }

  &-sub-title {
    &.sm {
      font-size: 1.4rem;
    }
  }

  &-login {
    padding: 5.8rem 1rem 3rem 1rem;

    .s-title {
      font-size: 2rem;
    }

    .s-header {
      padding: 0rem 0 1.2rem;
    }

    .s-title {
      &.md {
        font-size: 2rem;
      }

      &.sm {
        font-size: 1.2rem;
      }
    }

    .bottle {
      margin: 0 auto;
      text-align: center;
      position: absolute;
      left: 50%;
      top: -12%;
      transform: translate(-50%, -50%);
      height: 200px;
    }
  }

  &-label {
    color: $secondary;
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  &-alert {
    font-size: 1.8rem;
  }

  &-title {
    font-size: 2rem;
  }

  &-header {
    padding: 1.6rem 1.2rem;

    .close {
      margin: 0 0 0 auto;
      padding: 0;

      img {
        width: 20px;
      }
    }
  }

  &-response {
    margin-top: -20px;

    .modal {
      &-heading {
        font-size: 2rem;
      }

      &-description {
        font-size: 1.8rem;
        line-height: 2rem;
      }

      &-notification {
        color: $dark;
      }
    }
  }

  &-content {
    border-radius: 1.2rem;
  }

  &-unique-code {
    padding: 8px 20px 20px 20px;

    label {
      font-size: 2rem;
      display: block;
      text-align: center;
      font-weight: 600;
      margin: 16px 0;
    }

    input {
      background-color: #f2f1f2;
    }
  }

  &-code {
    text-align: center;
  }

  &-heading {
    font-size: 2.8rem;
    font-weight: 600;
  }

  &-description {
    font-size: 2.4rem;
  }

  &-notification {
    font-size: 1.4rem;
    color: $secondary;

    span {
      color: $danger;
      font-weight: 700;
    }
  }

  &-video-wrapper {
    .modal {
      &-header {
        padding-bottom: 0;

        .close {
          width: 20px;
          margin-right: -10px;
          img {
            position: absolute;
            right: 10px;
            z-index: 4;
          }
        }
      }

      &-body {
        margin-bottom: 6px;
        padding-top: 0;

        video {
          max-height: 52vh !important;
        }

        .react-player {
          width: auto !important;
          height: calc(100% - 115px) !important;

          @media (min-width: 767.98px) {
            height: calc(100% - 102px) !important;
            margin-bottom: -10px;
          }
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    &-heading {
      font-size: 2rem;
    }

    &-nojoke {
      h5 {
        font-size: 1.6rem;
      }
    }

    &-response {
      .modal {
        &-heading {
          font-size: 1.8rem;
        }

        &-description {
          font-size: 1.6rem;
          line-height: 1.8rem;
        }

        &-notification {
          font-size: 1.2rem;
        }
      }
    }

    &-sub-title {
      &.sm {
        font-size: 1.2rem;
      }
    }

    &-spacing {
      padding: 0vw 18vw;
    }

    // &-surprise{
    //   .img {
    //     margin-top: -20px;
    //    }
    // }
    &-description {
      font-size: 1.6rem;
    }

    &-title {
      font-size: 1.6rem;
    }

    &-notification {
      font-size: 1rem auto;
    }

    &-dialog {
      margin: 1rem auto;

      @media (max-width: 420px) {
        margin: 1rem;
      }
    }

    &-onboard {
      margin: 4.5rem auto 1rem auto;

      @media (max-width: 420px) {
        margin: 4.5rem 1rem 1rem 1rem !important;
      }

      video {
        max-height: 240px;
      }
    }

    &-alert {
      font-size: 1.4rem;

      @media (max-width: 350px) {
        font-size: 1.2rem;
      }
    }

    &-icon {
      width: 50px;
    }
  }
}

.modal {
  &-share-card {
    .modal {
      &-header {
        height: 1px;
        padding: 0;

        .close {
          position: relative;
          z-index: 4;
          right: 22px;
          top: 22px;
        }
      }
    }
  }
}

.reward-card-modal:has(.modal-reward) {
  padding: 0;
  margin-top: -50px;
}

.reward-card-modal:has(.modal-unique-code) {
  padding: 20px;
  margin-top: -50px;
}

.reward-pay .modal-header .close {
  position: relative;
  z-index: 999 !important;
}

.reward {
  &-pay {
    .modal {
      &-unique-code {
        label {
          line-height: 1.2;
          font-size: 21px;

          @media (min-width: 386px) {
            font-size: 2.4rem;
          }
        }
      }
    }

    .modal {
      &-reward {
        h4 {
          font-size: 20px;
          margin-top: 20px;
        }

        .btn-card {
          padding: 8px 12px;
          font-size: 12px;
          margin: 0 10px;
        }

        p {
          font-size: 16px;
          padding-top: 10px;
        }

        .redeem-date {
          font-size: 12px !important;
          font-weight: 600;
        }

        .para {
          font-size: 15px;
        }

        a {
          text-decoration: underline;
          font-size: 12px;
        }

        .or-copy-link {
          width: 280px;
          margin: 0 auto;

          .copy-btn {
            font-size: 12px;
          }

          input {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
