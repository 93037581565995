.hi_global {
  .s {
    &-comingsoon {
      h3 {
        font-size: 3.3vw;
      }
    }
    &-comic{
      .btn{
        font-size: 5px;
      }
    }
    &-fab {
      h3 {
        font-size: 3.3vw;
      }
    }
  }
  .modal {
    &-title {
      font-size: 1.2rem;
    }
    .btn-link {
      font-size: 1rem;
    }
  }
  // forms UI
  .form-custom-select {
    line-height: 2;
    padding: 1rem 3.2rem 1rem 1.7rem;
  }
  @media (min-width: 767.98px) {
    .sprite-points-wrapper {
      h3 {
        font-size: 1vw;
      }
    }
    .modal {
      &-title {
        font-size: 1.6rem;
      }
    }
    .s-comic{
      .btn{
        font-size: 15px;
      }
    }
  }
}
