.page {
  &-header {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    padding: 1.7rem 1.6rem;
   z-index: 3;
 background-color: $light;
    &-logo {
      min-width: 5.2rem;
      &-md {
        min-width: 6.4rem;
      }
   
    }
    .icon{
      &-back{
        position: absolute;
        top: 16px;
        z-index: 1;
       
      }
    }
    &-bottom {
      padding-top: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: 992px) {
      &-logo {
        min-width: 7.7rem;
        &-md {
          min-width: 7.7rem;
        }
      }
    }
  }
}
