.te_global {
  .modal{
    &-description{
      font-size: 1.2rem;
    }
  }
  .reward-pay{
    label{
      font-size: 14px;
    }
    
  }
  .display-1{
    font-size: 1rem;
  }
  .contest{
    @media (max-width:400px) {
      &-banner{
        h3{
      font-size: 3vw;
    }
   
    }
    &-para{
      font-size: 1.4vw;
    }
  
    }
   
  }
  .wallet{
    .card{
      &-reward{
        .btn{
          font-size: 6px;
          padding: 8px;
        }
      }
   
    }
  }
  .btn {
    &-artist {
      font-size: 0.6rem;
      padding: 0.8rem 1.7rem;
    }
    &-suprise {
      .text {
        line-height: 1.2rem;
      }
    }
  }
  .offcanvas-menu {
    max-width: 80%;
    .dropdown-item {
      font-size: 9px;
    }
  }
  .sprite-points-wrapper {
    h3 {
      font-size: 2vw;
    }
  }
  .card {
    &-wrapper{
      h4{
        font-size:1rem;
      }
      button{
        font-size: 8px;
        padding: 8px;

      }
      p{
        font-size: 0.8rem;
      }
    }
    &-comedian {
      .card {
        &-text {
          font-size: 8px;
        }
      }
    }
  }

  .s {
    &-title {
      font-size: 2.86vw;
    }

    &-comingsoon {
      h3 {
        font-size: 2.434vw;
        line-height: 3vw;
      }
      h6 {
        font-size: 2.2vw;
        line-height: 2.7vw;
      }
      h4 {
        font-size: 3vw;
      }
      .timer small {
        font-size: 0.6rem;
      }
    }
    &-comic {
      h6 {
      }
      h3 {
        font-size: 2.79vw;
      }
      .btn {
        font-size: 0.6rem;
      }
    }
    &-title {
      font-size: 2.645vw;
    }
    &-fab {
      h3 {
        font-size: 2.298vw;
      }
      h6 {
        font-size: 2.69vw;
        line-height: 3.2vw;
      }
    }

    &-laugh {
      .btn {
        font-size: 2vw;
      }
    }
    &-sub-title {
      font-size: 8px;
    }
    &-header {
      .view-more {
        font-size: 6px;
      }
    }
  }

  .wp-wrapper {
    span {
      font-size: 2.85vw;
    }
    .btn {
      font-size: 2.6vw;
      padding: 5px 10px;
    }
  }
  @media (min-width: 767.98px) {
    .form-group > .text-danger {
      font-size: 1rem;
    }
    .wallet,
    .contest {
      .card {
        &-title {
          // font-size: 2rem;
        }
        &-text {
          // font-size: 1.6rem;
        }
      }
    }
    .sprite-points-wrapper {
      h3 {
        font-size: 1vw;
      }
    }
    // .navbar-expand-lg .navbar-nav {
    //   display: none;
    // }
    .btn {
      &-artist {
        font-size: 1.6rem;
      }
      &-suprise {
        .text {
          line-height: 2rem;
          font-size: 1.4rem;
        }
      }
    }
    .offcanvas-menu {
      .dropdown-item {
        display: none !important;
      }
    }
    .s {
      &-title {
        font-size: 2.245vw;
      }
      &-shareJokes {
        h6 {
          font-size: 2.16vw;
          line-height: 3vw;
        }
      }
      &-comingsoon {
        h3 {
          font-size: 1.8vw;
          line-height: 3vw;
        }
        .timer small {
          font-size: 2.6rem;
        }
      }

      &-comic {
        .btn {
          font-size: 1.15vw;
          margin-top: 25px;
        }
        h3 {
          font-size: 2.39vw;
        }
      }
      &-laugh {
        .btn {
          font-size: 1.5vw;
          padding: 1vw 4.8vw;
        }
      }
      &-fab {
        h3 {
          font-size: 1.68vw;
        }
      }
      &-sub-title {
        font-size: 1.6rem;
      }
      .card {
        &-comedian {
          .card {
            &-text {
              font-size: 16px;
            }
          }
        }
      }
      &-header {
        .view-more {
          font-size: 18px;
        }
      }
    }
  }
}
