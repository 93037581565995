.gu_global {
  .view-more {
    font-size: 9px;
  }
  .form {
    &-custom-select {
      line-height: 2;
      padding: 1rem 3.2rem 1rem 1.7rem;
    }
  }
  .s {
    &-sub-title {
      font-size: 0.9rem;
    }
    &-title {
      font-size: 1.4rem;
    }
    &-laugh {
      .btn {
        font-size: 8px;
      }
      h3 {
        font-size: 5vw;
        line-height: 6vw;
      }
    }
    &-comic {
      h6 {
        font-size: 4.18vw;
      }
    }
  }
  @media (min-width: 767.98px) {
    .view-more {
      font-size: 2rem;
    }
    .s {
      &-laugh {
        .btn {
          font-size: 2rem;
        }
      }
      &-title {
        font-size: 3rem;
      }
      &-sub-title {
        font-size: 2rem;
      }
    }
  }
}
