.form {
  &-address {
    .form-group {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $dark;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: $dark;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: $dark;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: $dark;
      }
    }
  }
  &-page{
    font-size: 1.2rem;
    position: absolute;
    top: 12px;
   right: 15px;
  }
  &-progress{
   
    .form{
      &-group{
       
        div{
          display: flex;
          align-items: center;
         }
      }
    }
  
  }
  &-category-slider {
    .slick-prev:before,
    .slick-next:before,
    .slick-next {
      display: none !important;
    }
  }
  &-invert {
    border-radius: 5px;
    padding: 4rem 1.6rem;
    background: var(--Sprite-Primary-Color-White, #fff);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    .bottle {
      margin: -170px auto 0 auto;
      display: flex;
    }
  }
  &-signup {
    margin-top: 50px;
    .dropdown-center {
      text-align: center;
    }
    .bottle {
      display: none !important;
    }
    a {
      font-weight: 700;
    }
  }
  &-upload {
    label {
      display: block;
    }
    .form-check-input.is-invalid ~ .form-check-label {
      color: $dark;
    }
    .error {
      div {
        &.text-danger {
          position: unset;
          text-align: center;
        }
      }
    }

    // select {
    //   color: #00000066;
    // }
  }
  &-claim {
    background: $light;
    .form-add-btn {
      background-color: transparent !important;
      &:hover {
        background-color: transparent !important;
      }
    }
    .claim-address {
      border-radius: 10px;
      background: #fef6b3 !important;
      border: 1px solid $grey-2;
      padding: 10px !important;
      margin-bottom: 10px;
      img {
        width: 11px;
      }

      .address-divider {
        display: none;
      }
    }
    // .footer-claim-form {
    //   position: fixed;
    //   bottom: 0;
    // }
    .card {
      all: unset;
      width: 100%;
    }
    .s {
      &-title {
        // text-transform: none;
      }
    }
  }
  &-refer {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 0;
    position: relative;
    &-btn {
      text-wrap: nowrap;
    }
    .text-danger {
      position: absolute;
      left: 0;
      top: 100%;
    }
  }
  &-add-btn {
    position: relative;
    float: right;
    font-size: 1.4rem;
  }
  &-group {
    margin-bottom: 2.4rem;
    position: relative;
    label {
      font-size: 1.4rem;
      line-height: 1;
      margin-bottom: 1.2rem;
      font-weight: normal;
    }
    & > .text-danger {
      font-size: 1.1rem;
      line-height: 1.1rem;
      margin-top: 0.4rem;
      margin-bottom: 1.4rem;
      position: absolute;
      top: 100%;
      left: 1rem;
      @media (max-width: 384px) {
        font-size: 2.9vw;
      }
    }
  }
  &-control {
    // line-height: 4;
    padding: 1rem 1.7rem;
    &:disabled {
      color: #00000066;
    }
  }
  &-square {
    flex: 1;
    margin: 5px;
    padding: 1rem;
    max-width: 4.5rem;
    height: 4.5rem;
    border-radius: 0.4rem;
    border: 0;
    line-height: 1;
  }
  &-select {
    border: 1px solid #838383;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    position: relative;
    font-size: 1.6rem;
    padding: 0.675em 2.2rem 0.675em 1em;
    background: $light url("~/public/assets/img/icons/select-icon.svg")
      no-repeat calc(100% - 7px) center;
    background-size: 1.2rem;
    color: $dark;
    cursor: pointer;
    &:focus-visible {
      outline: unset;
    }
    &-border {
      background-color: transparent;
      border: 1px solid $secondary;
      background-position: calc(100% - 10px);
      padding: 1rem 2.3rem 1rem 1rem;
      color: $dark;
      border-radius: 0.8rem;
      font-size: 1.2rem;
    }
    &-language {
      font-size: 1.2rem;
      padding: 0.2rem 0.2rem 0.2rem 0.5em;
      padding-right: 2.2rem;
  background: transparent url("~/public/assets/img/icons/select-icon.svg")
        no-repeat calc(100% - 7px) center;
      background-size: 0.8rem;
      border: 1px solid $dark;
      color: $dark;
    }
  }

  &-custom-select {
    display: block;
    width: 100%;
    height: calc(1em + 3.4rem + 2px);
    padding: 1rem 3.2rem 1rem 1.7rem;
    background-position-x: calc(100% - 24px);
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "Aktiv Grotesk";
    line-height: 1.5;
    color: #000000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ffffff;
    border-radius: 2.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-right: 43px;
  }
  &-otp {
    .input-group {
      justify-content: center;
    }
  }
  &-refer {
    input {
      background-color: $grey-2;
    }
    .btn {
      // padding: 0.8rem 1rem;
      white-space: nowrap;
    }
  }

  &-footer {
    text-align: center;
    margin-top: 2.6rem;
  }
  &-check {
    opacity: 0;
    cursor: pointer;
    height: 0;

    width: 0;
    & + label {
      position: relative;
      padding-left: 2.3rem;
      margin-bottom: 0;
      // top: -4px;

      &::before {
        content: "";
        border-radius: 2px;
        border: 1px solid #676767;
        width: 15px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: -2px;
      }
      &.small {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
    &:checked {
      & + label {
        &::before {
          border-color: $dark;
          background: $dark url("~/public/assets/img/icons/check.svg") no-repeat
            center center;
          top: -2px;
          @media (max-width: 767.98) {
            top: -2px;
          }
        }
      }
    }
  }
  &-date {
    position: relative;
    line-height: 2 !important;
    &::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
    }
  }

  .profile-info-card {
    top: 2rem;
    right: 1rem;
  }
  @media (min-width: 576px) {
    &-wrapper {
      background-color: $light;
    }

    &-invert {
      input[type] {
        background-color: $grey-2;
        &::focus {
          background-color: $grey-2;
        }
      }
    }
  }

  @media (min-width: 768px) {
    &-custom-select {
      font-size: 1.8rem;
      padding: 0.6rem 3.2rem 0.6rem 1.7rem;
      line-height: 2;
    }

    &-address {
      .form-custom-select {
        font-size: 1.4rem;
      }
    }
    &-upload {
      &-format {
        width: 100vw;
        max-width: 50vw;
        left: 50%;
        margin-left: -25vw;
      }
      label {
        font-size: 2.4rem !important;
        text-align: center;
      }
      input.form-control {
        max-width: 500px;
        font-size: 1.8rem;
        margin: 0 auto;
      }
      .form {
        &-check-label {
          font-size: 1.6rem !important;
          line-height: 2rem;
        }
        &-custom-select {
          max-width: 500px;
          margin: 0 auto;
          font-size: 1.8rem;
        }
      }
    }
    &-claim {
      background: unset;
      .claim-address {
        padding: 20px !important;
        margin-bottom: 20px;
        img {
          width: 15px;
        }
      }
      .profile-address {
        max-width: 100%;
        padding: 0;
      }

      .address-tag {
        font-size: 1.6rem;
        font-weight: normal;
      }
      // .s {
      //   &-title {
      //     font-size: 2rem;
      //     text-align: center;
      //     font-weight: normal;
      //   }
      // }
      .form {
        &-control {
          background-color: $light;
        }
      }
    }
    &-group {
      margin-bottom: 3rem;
      & > .text-danger {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
      label {
        font-size: 1.6rem;
        margin-bottom: 1.4rem;
        &.small {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }

    &-select {
      &-border {
        padding: 1rem 3rem 1rem 2rem;
        font-size: 1.6rem;
      }
    }
    &-refer {
      label {
        text-wrap: nowrap;
        line-height: 1;
        margin: 0 1rem 0 0;
      }
      .btn {
        padding: 0.8rem 2rem;
        margin-left: 2rem;
      }
    }
  }
  @media (min-width: 767px) {
    &-select {
      &-language {
        font-size: 1.8rem;
        border-radius: 4px;
        background-size: 1.2rem;
      }
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: 0 !important;
  background-image: none !important;
}
.login {
  position: relative;
  top: 20rem;
}
.SpriteResendOTP,
.filter {
  cursor: pointer;
}
.login_journey {
  .modal {
    background: #666666;
  }

  .modal {
    &-signup {
      // margin: 1.75rem 1rem;
      .modal {
        &-body {
          padding: 0.8rem 1rem 3rem 1rem;
        }
      }
    }
  }

  .profile-detail {
    padding-left: 16px !important;
    padding-right: 16px !important;

    img {
      display: block;
    }
    .dropdown-center {
      text-align: center;
    }
  }
  // input{
  //   border: 1px solid $primary;
  // }
  a {
    font-weight: 700;
  }
}

@media (min-width: 767.98px) {
  .form-add-btn {
    font-size: 1.6rem;
  }
  .modal-icon {
    width: 70px;
  }
}
.react-datepicker__input-container:before {
  content: "";
  display: block;
  width: 15px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 17px;
  background: url("~/public/assets/img/icons/calendar.svg") no-repeat center
    center;
  background-size: 100%;
  z-index: 1;
}
.footer-wrapper {
  margin-bottom: -5px;
}
.react-datepicker__close-icon::after {
  background-color: #009639 !important;
  height: 18px !important;
  width: 18px !important;
  z-index: 2;
  position: relative;
  right: 9px;
  top: 1px;
}
input[type="text"] {
  -webkit-appearance: textfield;
}
