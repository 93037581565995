.card-image-container {
  text-align: center;
  padding: 0 2rem;
  background-color: $light;

  img {
    width: 17vw;
  }
}
.category-icon {
  min-width: 57px;
  min-height: 57px;
}
.my-profile {
  .profile-address {
    padding-bottom: 10px;
    .address-font-style:last-child {
      padding-bottom: 0;
    }
  }

  .form {
    &-control {
      background-color: $light;
    }
    .footer-claim-form {
      bottom: 0;
      position: fixed;
    }
  }
  .react-datepicker {
    font-size: 1.4rem;
  }
  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-around;
  }
  .react-datepicker__day-names {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 1.5rem;
    margin-bottom: 3px;
  }
}
.avatar {
  &-wrapper {
    position: relative;
    .avatar-edit {
      bottom: 0;
      right: 0;
      position: absolute;
    }
    & + .dropdown {
      &-menu {
        // min-width: 20rem;
        border-radius: 5px;
        max-width: 150px;
        // left: 50% !important;
        // transform: translateX(-50%) !important;
        border: 1px solid $grey-2;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
        // top: 100% !important;
        margin-top: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        img {
          margin: 0.2rem;
          max-width: 3.7rem;
        }
        &:after {
          left: 50%;
          transform: translateX(-50%);
          display: none;
        }
        .dropdown-item {
          color: $dark;
          font-size: 1.2rem !important;
          padding: 1.2rem;
          border-bottom: 1px solid $grey;
          &:last-child {
            border: unset;
          }
        }
      }
      &-menu:not(.show) {
        display: none;
      }
    }
  }
  &-img {
    width: 5.3rem;
    height: auto;
    border-radius: 100%;
  }
}

.avatar-select-wrapper {
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  width: 67%;
  max-width: 186px;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 2;
  background-color: #ffffff;
  border: 1px solid #cdcdcd;
  img {
    margin: 0.2rem;
    max-width: 3.7rem;
  }
}
.profile {
  &-address {
    padding: 1.4rem 1.4rem;
  }
  &-progress{
    .form{
      &-group{
        label{
          margin-bottom: 0px;
          line-height: 1.6;
         
        }
        input{
          margin-top: 6px;
          margin-right: 6px;
        }
      }
    }
  }
  &-score {
    p {
      margin-bottom: 0;
      font-size: 1rem;
      white-space: nowrap;
      margin-top: 0;
    }
    img {
      width: unset !important;
      margin-right: 3px;
    }
    .btn-wrapper {
      text-align: end !important;
    }
  }
  &-detail {
    padding-left: 0;
    padding-right: 0;
    .bottle {
      display: none;
    }
    .dropdown-center {
      text-align: left;
    }
  }
  &-info {
    padding: 1.3rem 0;
    border-top: 1px solid $grey;
    .btn {
      padding: 8px 20px;
      white-space: nowrap;
      font-size: 11px;
    }
    img {
      width: 1.8rem;
    }
    .border {
      &-seperator {
        border-left: 1px solid #959494;
        border-right: 1px solid #959494;
        opacity: 0.4;
      }
    }
  }
}
//Profile Web Camera
.camera-wrapper {
  overflow: hidden;
  video {
    height: auto;
    overflow: hidden;
    width: 100%;
    padding: 1rem;
  }
}
.icon {
  &-notifi,
  &-back {
    color: $dark;
  }
}
.profile-card {
  width: 100% !important;
  &.wallet {
    padding: 1rem 1rem 1rem 2rem;
  }
}

@media (min-width: 768px) {
  .profile {
    &-info {
      img {
        width: 2.2rem;
      }
    }
    &-score {
      p {
        font-size: 2rem;
      }
      h3 {
        font-size: 2.4rem;
      }
      .border-seperator {
        border-right: none;
      }
      img {
        width: 1.8rem !important;
        margin-right: 7px;
      }
      .btn {
        padding: 1.2rem 2.4rem;
        font-size: 1.4rem;
      }
      @media (min-width: 768px) and (max-width: 1300px) {
        p {
          font-size: 1.2vw;
        }
        h3 {
          font-size: 1.3vw;
        }
      }
    }
  }
  .profile-points-sign {
    width: 5.2rem !important;
  }
  .my-profile {
    .form {
      &-control {
        font-size: 18px;
        color: $dark;
      }
    }
  }
  // .avatar-img{
  //   width: 10rem;
  // }
  .points-num-sign {
    font-size: 1.3rem;
    padding: 0.1 0.7rem 0.1 1.2rem !important;
  }
  .walletimgdiv {
    display: flex !important;
    flex-direction: column;
    align-items: end;
    img {
      width: 2.6rem;
    }
    h4 {
      text-align: end;
      text-wrap: nowrap;
      margin-bottom: 0.75rem;
    }
  }
  .profile-header {
    // padding-left: 4rem !important;
    text-align: left;
  }
  .profile-header-container {
    padding-left: 0rem !important;
  }
  .user-name {
    font-size: 1.8rem;
  }
  .user-mobile {
    font-size: 1.3rem;
  }
  .user-email {
    font-size: 1.3rem;
  }
}

.custom-table {
  tr {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  }
  tbody,
  thead {
    th,
    td {
      border: none;
      font-size: 1.2rem;
      padding: 1rem;
      @media (max-width: 370px) {
        font-size: 1rem;
      }
    }
    th,
    td {
      margin-bottom: 0.8rem;
      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }
      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
      }
    }
  }
  th {
    background: $warning;
    color: $dark;
  }
  .pending {
    background-color: $light;
    color: #908e8e;
  }
  .accept {
    background-color: #f1f1f1;
    border: 1px solid #fff;
  }
  @media (min-width: 767.98px) {
    th {
      font-size: 2rem !important;
      padding: 1.4rem;
      &:first-child {
        padding-left: 32px;
      }
      &:last-child {
        padding-right: 32px;
        text-align: end;
      }
    }
    td {
      font-size: 1.8rem !important;
      &:last-child {
        padding-right: 32px;
        text-align: end;
      }
    }
  }
}

.profile-table {
  width: -webkit-fill-available;
  border-collapse: separate;
  border-spacing: 0 1rem;
  width: 100%;
}
.refer-info {
  h3 {
    font-size: 1.4rem;
  }
  p {
    font-size: 1rem;
    color: #484848;
  }
  @media (min-width: 767.98px) {
    h3 {
      font-size: 2.8rem;
    }

    p {
      font-size: 2rem;
    }
  }
}

.my-profile-header {
  @media (min-width: 768px) {
    padding: 0 3.6rem !important;
  }
  @media (min-width: 1440px) {
    padding: 0 4.4rem !important;
  }
}

.my-profile {
  // background-color: white;
  .status-msg {
    position: relative;
    top: 8px;
  }

  .edit {
    width: 1.2rem;
  }
  .progress-bar {
    height: 0.4rem;
    top: 9.3rem;
    left: 0;
    @media (min-width: 768px) {
      top: 11.3rem;
    }
  }
  .edit-icon {
    position: absolute;
    top: 138px;
    right: -4px;
    width: 1.2rem;
    @media (min-width: 768px) {
      width: 23px;
      top: 248px;
      right: 20px;
    }
  }
  .moredetailtext {
    font-weight: 10;
    text-align: center;
  }
  .s-sub-title {
    color: #484848;
  }
  .profile-mobile-sign {
    min-width: 1.5rem;
  }
  .profile-address-sign {
    min-width: 1.8rem;
    align-self: self-start;
    margin-top: 0.2rem;
  }
  .mobile-no-info {
    font-size: 1.4rem;
    padding-left: 1rem;
  }
  .default-address-sign {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.8rem;
  }
  .default-address-div {
    width: 96vw;
    position: relative;
    left: 57%;
    right: 49%;
    margin-left: -51vw;
    padding: 1.2rem;
    border-radius: 1rem;

    @media (min-width: 445px) {
      width: 95vw;
    }
    @media (min-width: 575px) {
      width: 93vw;
      margin-left: -51vw;
    }
    @media (min-width: 768px) {
      width: 83vw;
      margin-left: -45vw;
    }
    @media (min-width: 925px) {
      width: 86vw;
      margin-left: -47vw;
    }
    @media (min-width: 1225px) {
      width: 89vw;
      margin-left: -49vw;
    }
    @media (min-width: 1468px) {
      width: 84.5vw;
      margin-left: -47vw;
    }
    @media (min-width: 1600px) {
      width: 77vw;
      margin-left: -43vw;
    }
  }
  .cs-coin-image {
    @media (min-width: 768px) {
      width: 3rem;
      margin-left: 1rem;
    }
  }
  .collect-more {
    cursor: pointer;
  }
  .collectmore-outer {
    @media (min-width: 768px) {
      padding: 0 5rem;
    }
  }
}

@media (max-width: 575.98px) {
  .coinstext {
    font-size: 1.7rem !important;
  }
}
.progress-bar-container img {
  width: 70px;
}
.profile-img-div {
  justify-content: center;
  display: flex;
}
.profile-img-div,
.progress-bar-container {
  position: relative;

  .badge {
    position: absolute;
    top: -8px;
    right: 0px;
  }
}
.profile-status {
  position: absolute;
  font-size: 10px;
  border: 2px solid $light;
  background: #ffe200;
  top: 80px;
  text-align: center;
  font-weight: 700;
  margin: 0;
  border-radius: 100px;
  z-index: 3;
  padding: 0px 4px;
}
.user-profile-image {
  border-radius: 50%;
  @media (max-width: 475px) {
    width: 8rem;
  }
  @media (min-width: 768px) {
    width: 11rem;
  }
}

.profile-progress-bar {
  width: 9rem !important;
  // @media (min-width: 475px) {
  //   width: 10rem !important;
  // }
  @media (min-width: 768px) {
    width: 19rem !important;
  }
}
.user-mobile {
  font-size: 1.2rem;
}

.user-email,
.user-mobile {
  color: #5c5c5c;
  font-style: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.2rem;
  font-weight: 400;
  img {
    margin-right: 3px;
  }
}
.s-sub-title {
  color: #313131;
}

.refer-now-btn {
  height: 4.75rem;
  padding: 1rem 1.8rem;
  white-space: nowrap;
}

.address-add-btn {
  color: $primary;
}

.address-tag {
  color: #000;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 100;
  line-height: 0.875rem;
}

.default-address-label {
  font-size: 1.2rem;
  color: #000;
  font-weight: 100;
  line-height: normal;
}

.address-font-style {
  color: #000;
  font-size: 14px;
  padding-top: 0;
  font-weight: 100;
  word-wrap: break-word;
  line-height: 1.5;
}
.address-card {
  border-radius: 5px;
  border: 0.5px solid rgba(217, 217, 217, 0.5);
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
}
.profile-name-display {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
  padding-bottom: 1rem;
  white-space: nowrap;
  padding-top: 5px;
  height: 30px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.refer-btns {
  padding: 0.8rem 2.4rem;
  border: 1px solid $primary;
  text-wrap: nowrap;
  font-size: 2.94vw;
  @media (min-width: 768px) {
    width: unset;
    font-size: 1.6rem;
  }
}

.address-divider {
  border-bottom: 1px solid #cdcdcd;
}
.disabled-emoji {
  opacity: 0.4;
}
@media (min-width: 767.98px) {
  .profile-name-display {
    font-size: 3rem;
    height: 50px;
  }
  .profile-col {
    border-right: 1px solid #cdcdcd;
  }
  .profile-col:last-child {
    border-right: none;
  }
  .profile-status {
    top: 172px;
    font-size: 2rem;
    padding: 0px 7px;
    border: 4px solid $grey-2;
  }
  .address-tag {
    font-size: 1.8rem;
  }
  .user-email,
  .user-mobile {
    font-size: 2rem;
    img {
      width: 27px;
      margin-right: 5px;
    }
  }
}
@media (min-width: 767.98px) {
  .category-icon {
    min-width: 108px;
    min-height: 108px;
  }
  .progress-bar-container {
    img {
      width: 151px;
    }
  }
  .profile-img-div {
    .badge {
      width: 60px;
      right: 20px;
    }
  }
}
