.leaderboard {
  &-rank{
  background:  #C6E3D1 !important;
  }
  &-rowRank{
    position: relative;
    top: -50px;
  }
  .date-picker-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 300px;
    height: 300px;
    border-radius: 2rem;
    background: $light;
  }
  .react-datepicker {
    font-family: "Aktiv Grotesk" !important;
    border: none;
    width: 300px;
    color: #333;
    padding: 16px 8px 8px 8px;
    border-radius: 2rem;
  }

  .react-datepicker__month-container {
    float: unset;
  }
  button.react-datepicker__navigation.react-datepicker__navigation--previous {
    left: 239px;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #333;
    top: 16px;
  }
  .react-datepicker__header.react-datepicker__header:not(
      .react-datepicker__header--has-time-select
    ) {
    background-color: $light;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }
  .react-datepicker__current-month {
    font-size: 2rem;
    color: #333;
    text-align: left;
    padding-left: 15px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 14px;
    color: #333;
    line-height: 2.8rem;
    width: 3rem;
  }
  .react-datepicker__day-names {
    margin: 3px 8px;
    font-weight: bold;
  }
  .react-datepicker__week {
    text-align: center;
    font-size: 1.2rem;
  }
  .date-picker-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center;

    align-items: center;
    z-index: 999; /* Ensure the overlay is behind the date picker */
  }

  .filter {
    align-items: center;
    display: flex;
    justify-content: end;
    max-width: 160px;
    float: left;
    white-space: nowrap;
  }
  .btn {
    &-outline {
      color: $dark;
      border: 1px solid $primary;
      background-color: transparent;
    }
  }

  .tickle-table {
    tr {
      background-color: $light !important;
    }
    td {
      margin-bottom: 0;
    padding: 6px;
    &:last-child{
      padding: 0;
      img{
        width: 41px;
        height: 35px;
      }
    }
      img {
        width: 25px;
        height: 28px;
      
      }
    }
    span {
      font-size: 1rem;
      color: #333;
    }
  }

  @media (min-width: 767.98px) {
    .filter {
      top: -35x;
      span {
        font-size: 2rem;
      }
    }
    .profile-table {
      span {
        color: $dark !important;
      }
    }
    .tickle-table,
    .custom-table {
      .bg-white {
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
      }

      th:first-child,
      td:first-child {
        text-align: center;
      }
      td {
        padding: 10px;
        
        img {
          margin-left: 0 !important;
          width: 33px;
          height: 33px;
        }
      }
      td:last-child {
        img {
          width: 41px;
          height: 42px;
        }
      }
      th,
      h2 {
        font-size: 2rem;
      }
      span {
        font-size: 1.8rem;
        color: $grey;
      }
      td,
      p {
        font-size: 1.6rem;
        h3 {
          font-size: 1.8rem;
        }
      }
    }
  }
}
// .tab{
//   &-main{
//     justify-content: start;
//     .tabs-wrapper{
//       background-color: transparent;
//       border-radius: unset;
//     border: 0;
//   a.tab.active{
//     background-color: transparent;
//   }
// }

// .active {
//   color: $primary;

//   h3 {
//     color: $primary;
//   }
// }

// .tab.active::before {
//   content: '';
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 4px;
//   background-color: $primary;
//   border-top-left-radius: 5px;
//   border-top-right-radius: 5px;
// }
//     }
//   }

// .tab-home {
//   .tabs-wrapper {
//     display: flex;
//     max-width: fit-content;
//     margin: 0 auto;
//     border: 1px solid;
//     border-radius: 100px;
//     padding: 15px 28px;
//     a {
//       color: $grey;
//       padding: 10px 15px;
//     }
//     a:hover,
//     a.active {
//       color: $light;
//       background-color: $primary;
//     }
//   }
// }
.tab {
  &-home {
    .tabs {
      &-wrapper {
        display: flex;
        max-width: fit-content;
        margin: 0 auto;
        background-color: $light;
        border-radius: 100px;
        padding: 5px;
        a {
          border-radius: 10rem;
          padding: 7px 16px;
          h3 {
            color: #5e5e5e;
          }
          &.active {
            background-color: $primary;
            h3 {
              color: $light;
            }
          }
        }
      }
    }
  }
  @media (min-width: 767.98px) {
    &-home {
      .tabs {
        &-wrapper {
          padding: 8px;
          a {
            padding: 12px 24px;
            h3 {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
}
.hall-lame {
  .tickle {
    &-table {
      &-icon {
        width: 57px !important;
        height: 57px !important;
        min-height: 57px;
        min-width: 57px;
      }
      th:first-child {
        text-align: left;
      }

      td:first-child {
        text-align: left;
        padding-left: 1rem;
      }
      span {
        opacity: 0.3;
        font-size: 1rem;
      }
      .vote {
        padding-top: 10px;
      }
    }
  }
  @media (min-width: 767.98px) {
    .tickle {
      &-table {
        td:first-child {
          padding-left: 32px;
        }
        &-icon {
          width: 72px !important;
          height: 72px !important;
        }
        th,
        td {
          &:last-child {
            text-align: left;
          }
        }
        span {
          font-size: 1.4rem;
        }
      }
    }
  }
}
.react-datepicker__day--disabled{
  color: #ccc !important;
}
.visibility-hidden{
  visibility: hidden;
}