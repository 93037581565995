.contest {
  &-over {
    &-main {
      padding-bottom: 0;
    }
    .s {
      &-over {
        background: url("~/public/assets/img/bgs/contest_over_m.svg") no-repeat;
        background-size: cover;
      }
      &-comic {
        font-family: "Aktiv Grotesk";
        h6 {
          font-style: normal !important;
        }
      }
    }
  }
  &-banner {
    background: url("~/public/assets/img/bgs/contest-bg.svg") no-repeat;
    background-size: cover;
    border-radius: 1rem;
    padding: 8px 0;
    justify-content: space-evenly;
    h3 {
      font-size: 3.21vw;
    }
    @media (max-width: 370px) {
      justify-content: center;
      padding: 0;
    }
    @media (min-width: 767.98px) {
      padding: 25px;
      border-radius: 4rem;
      h3 {
        font-size: 2.62vw;
      }
      img {
        height: 195px;
      }

      .btn {
        padding: 12px 36px;
        font-size: 18px;
        margin: 1.5rem 0;
      }
      .contest {
        &-para {
          font-size: 2rem;
        }
      }
    }
    @media (min-width: 1300px) and (max-width: 2000px) {
      img {
        height: 250px;
      }
      .btn {
        font-size: 2.4rem;
        padding: 16px 36px;
      }
    }
  }
  &-para {
    font-size: 1.64vw;
    opacity: 0.8;
  }
}
.reward {
  &-share {
    background: url("~/public/assets/img/bgs/reward-share-bg.svg") no-repeat;
    background-size: cover;
    padding: 15px;
    .card {
      &-share {
        padding: 20px;
        border-radius: 5px;
        border: 1px solid #ffcb29;
        text-align: center;
        background: #fff;
        box-shadow: 50px 57px 21px 0px rgba(0, 0, 0, 0),
          32px 36px 19px 0px rgba(0, 0, 0, 0.04),
          18px 20px 16px 0px rgba(0, 0, 0, 0.13),
          8px 9px 12px 0px rgba(0, 0, 0, 0.21),
          2px 2px 7px 0px rgba(0, 0, 0, 0.25);
        .link {
          font-size: 8px;
          position: absolute;
          right: 0;
          bottom: 0px;
          color: $dark;
        }
      }
    }
  }
}
.sprite {
  &-points {
    h3.s-title {
      white-space: nowrap;
      text-transform: unset !important;
    }
    &-icon {
      min-width: 40px;
      min-height: 48px;
    }
    &-wrapper {
      background-color: $light;
      padding: 6px;
      h3 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.67vw;
        margin-bottom: 0;
      }
    }
    
    @media (max-width: 575.98px) {
      &-wrapper {
        min-height: 60px;
       padding: 4px;
        h3 {
          font-size: 2.2vw;
          min-height: 26px;
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (min-width: 767.98px) {
  .reward {
    &-share {
      padding: 24px;
      .card {
        &-share {
          padding: 12px;
          .link {
            font-size: 12px;
            bottom: -9px;
          }
        }
      }
    }
  }
  .contest {
    &-over {
      padding: 10rem 0 0rem 0;
      margin-bottom: 0;

      .s {
        &-over {
          background: url("~/public/assets/img/bgs/contest_over_d.svg")
            no-repeat;
          margin-bottom: 0;
          height: 950px;
          background-size: cover;
        }
      }
    }
  }
  .sprite {
    &-points {
      &-icon {
        min-width: 86px;
        min-height: 104px;
      }
      &-wrapper {
        padding: 13px;
        h3 {
          font-size: 1.9rem;
          margin-bottom: 1.5rem;
        }

        p {
          font-size: 1.6rem;
          img {
            // width: unset;
            // height: unset;
          }
        }
        @media (min-width: 767px) and (max-width: 1420px) {
          padding: 6px;
          h3 {
            font-size: 1.13vw;
          }

          p {
            font-size: 0.9vw;
          }
        }
      }
    }
  }
}
.tooltip {
  // font-size: 1vw;
  position: relative;
  .tooltip-inner {
    max-width: 220px;
    padding: 7px 12px;
    color: $dark;
    text-align: left;
    background-color: $light;
    border: 1px solid $primary;
    font-size: 1rem;
    border-radius: 5px;
    position: relative;
    left: -70px;
  }
  .tooltip-arrow {
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $primary;
    position: absolute;
    top: -5px;
    transform: translateX(-50%);
  }
  @media (max-width: 767px) {
    .tooltip-inner {
      max-width: 200;
      padding: 4px 7px;
      left: -40px;
      font-size: 2vw;
    }
    .tooltip-arrow {
      transform: translateX(-50%);
    }
  }
}

.tooltip-inner {
  left: -8px !important;
}

.contest {
  &-pool {
    .slick {
      &-slide {
        margin: 0 3px;
        @media (min-width: 991px) {
          margin: 0 25px !important;
        }
      }
    }
    .card {
      &-text {
        display: none;
      }
      .link-wrapper {
        display: none;
      }
    }
    @media (min-width: 767.98px) {
      .card {
        &-text {
          font-size: 1.6rem;
          min-height: 80px;
        }
      }
    }
  }
}
@media (max-width:767.98px) {
  .contest{
  &-bottle{
    height: 23vw;
  }
}
}

svg {
  transform: rotate(0deg);
}
 
svg text {
  text-anchor: middle;
  fill: black;
  font-size: 20px;
  offset: 50%;
}