.ugc {
  .joke-type {
    position: absolute;
    transform: translate(50%, -50%);
    left: 27%;
    top: 43%;
    width: 80px;
  }
  span.icon.icon-Right.icon-Right-arrow-01{
    color: $primary;
  }
  &-card {
    .card {
      &-joke {
        padding: 9px 16px;
      }
    }
  }
  .header {
    &-ugc {
      .search-wrapper {
        .search {
          input {
            border-radius: 10rem;
            border: 1px solid #cdcdcd;
            padding: 7px 15px 7px 28px;
            font-size: 1rem;
            width: 68vw;
          }

          &-img {
            position: absolute;
            top: 9px;
            left: 7px;
            width: 17px;
            height: 17px;
          }
        }

        button {
          font-size: 1.2rem;
        }
        .btn-danger {
          border-radius: 100%;
          border: none;
          width: 22px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        }
      }
    }
  }
  .img {
    &-block,
    &-card {
      border-radius: 0;
    }
  }
  @media (min-width: 767.98px) {
    .halllame{
      top: 55px;
    }
    .header {
      &-ugc {
        .search-wrapper {
          .search {
            input {
              height: 46px;
              padding: 12px 15px 7px 36px;
              font-size: 1.6rem;
              width: 415px;
            }

            &-img {
              position: absolute;
              top: 12px;
              left: 7px;
              width: 23px;
              height: 23px;
            }
          }

          button {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}
.bottle {
  display: none;
}
.category {
  .slick {
    &_dots {
      bottom: -20px !important;
    }
  }
}
.upload-form {
  form,
  .form {
    .ugc-text {
      border-radius: 4px;
      background: #99d4b0 !important;
      color: $dark;
      height: 108px;
      @media (min-width: 767.98px) {
        height: 140px;
      }
    }
    &-control {
      background-color: $light;
    }
    &-check-label {
      font-weight: normal;
      line-height: 1.8rem;
    }
    &-preview {
      .card {
        padding: 1.6rem;

        &-header {
          border-radius: 0;
          border: 0;
          padding: 1rem 0;
          .title {
            font-size: 1.4rem;
          }
          .subtitle {
            font-size: 1rem;
          }
          .title-icon {
            width: 30px;
          }
        }
        &-body {
          img {
            border-radius: 10px;
          }
        }
      }
      &-overlay {
        border-radius: 0px 0px 5px 5px;
        position: absolute;
        width: 100%;
        text-align: center;
        color: $light;
        display: block;
        padding: 1.2rem;
        font-size: 1.4rem;
        bottom: 0px;
        margin: 0;
        background: linear-gradient(
          120deg,
          rgba(255, 255, 255, 0.4) -19.22%,
          rgba(255, 255, 255, 0.1) 91.01%
        );
        backdrop-filter: blur(10px);
      }
      .retry {
        width: 11px;
      }
    }
  }

  label {
    font-weight: 700;
  }
  @media (min-width: 767.98px) {
    .form {
      &-preview {
        .card {
          &-header {
            .title {
              font-size: 2rem;
            }
            .subtitle {
              font-size: 1.6rem;
            }
            .title-icon {
              width: 48px;
            }
          }
        }
        .btn {
          font-size: 2.4rem;
        }
      }
    }
    .card {
      background: transparent;
      &-header {
        background-color: transparent;
        .title {
          font-size: 2rem;
        }
        .subtitle {
          font-size: 1.6rem;
        }
      }
    }
  }
}
.file-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 255px;
  @media (min-width: 767.98px) {
    width: 550px;
  }
}
.ugc_disclaimer{
  font-size: 11px;
  margin-left: 25px;
  margin-top: -2px;
  @media (max-width: 767.98px) {
    margin-left: 0px;
    margin-top: 5px;
  }
}
