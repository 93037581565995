.btn {
  &-collect {
    font-size: 1rem !important;
    padding: 8px 12px !important;
  }
  &-sm.vote{
    font-size: 1.2rem !important;
  }
&-primary{
  background-color: #11A64B !important;
  &:hover{
    opacity: 0.8;
  }
}
&-warning{
  background-color: #FFE200 !important;
  &:hover{
    opacity: 0.8;
  }
}
  &-underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &-contest {
    .btn {
      white-space: nowrap;
      font-size: 12px;
      padding: 6px 30px;
      display: flex;
      align-items: center;
      font-weight: 400;
  span{
   
    //   background: url("~/public/assets/img/contest/comic_coin.webp") no-repeat ;
    //   background-size: contain;
    //  display: inline-block;
    //  width: 25px;
    //  height: 25px;
    //  margin-right: 5px;
    }

   

      @media (max-width:360px) {
        font-size: 3.3vw;
        padding: 6px 25px;
      }
    }
  }

  &-unique-code {
    padding: 8px 12px;
    font-size: 1rem;
  }

  &-outline-dark {
    border: 1.5px solid $dark !important;
    color: $dark !important;
    font-size: 1.4rem !important;
    padding: 8px 36px !important;
    background-color: transparent !important;

    &:hover,
    &:focus-visible,
    &:active,
    &:focus {
      color: $dark !important;
    }
  }

  &-disabled {
    color: gray !important;
  }

  &-fixed {
    width: 75%;
    margin: 0 auto;
    display: block;
  }

  &-suprise {
    .btn {
      font-size: 1rem;
      position: fixed;
      padding: 4px 6px;
      min-width: 8.6rem;
      right: 16px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      top: 450px !important;
      z-index: 2;
      border: 0.8px solid var(--Brand-Green, #11a64b);
    }

    .text {
      font-size: 8px;
      line-height: 1.2;
    }
  }

  &-link {
    font-size: 1rem;
    font-weight: bold;

    &-lg {
      font-size: 1.4rem;
      line-height: 1;
    }
  }

  &-sm {
    padding: 0.7rem 1.6rem;
    font-size: 0.8rem;
    border-radius: 10rem;
  }

  &-follow {
    padding: 9px 20px 8px 20px;
    font-size: 12px;
  }

  &-modal {
    padding: 10px 28px;
  }

  &-outline.border {
    border: 2px solid $dark !important;
  }

  &-lg {
    padding: 1.2rem 1.6rem 1.2rem;
  }

  &-ex-lg {
    padding: 1.4rem 6rem;
  }

  &-category,
  &-upload {
    width: 20vw;
    height: 20vw;
    padding: 1rem;
    text-align: center;
    border-radius: 10px;
    background-color: #ffffff;
    border: 2px solid $light;
    margin-right: 8px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 1rem;
      padding-top: 3px;
      line-height: 1.3rem;
    }

    img {
      min-height: 30px;
      height: 100%;
    }

    &:active,
    &.active,
    &:focus,
    &:hover {
      border: 2px solid $primary;
    }
  }

  &-category {
    width: 20vw;
    height: 83% !important;

    @media (max-width: 374px) {
      .card {
        &-tickle {
          h6 {
            font-size: 9px;
          }
        }
      }
    }

    .card {
      &-tickle {
        &>div {
          all: unset;
        }
      }
    }

    img {
      width: 9vw;
      height: 9vw;
      min-height: unset;
    }

    &-active {
      border: 2px solid $primary;
    }
  }

  &-sidebar {
    padding: 10px 5px;
    border-radius: 5px;

    h6 {
      color: $light;
    }
  }

  &-claim-submit {
    width: 100%;
  }

  &-artist {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    margin-top: 1rem;
    white-space: nowrap;
  }

  &-format {
    display: flex;
    background: #99d4b0 !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    position: relative;
    height: 108px;
    border-radius: 5px;

    img {
      width: 40px;
    }

    .close {
      all: unset;
      position: absolute;
      right: 15px;
      top: 0;

      span {
        font-size: 20px;
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    background: #fef6b3 !important;
    color: $dark;
    justify-content: space-between;

    .icon {
      width: 2.4rem;
      height: 2.4rem;
      background-color: $light;
      border-radius: 1rem;
      color: $dark;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover{
 background-color: #FFE200 !important;     
    }
  }

  &-claim {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 1;

    .icon {
      width: 1.2rem;
      margin-right: 0.5rem;
    }
  }
}

button {
  &.bg-light {

    &:hover,
    &:focus {
      background-color: $light !important;
    }
  }
}

@media (min-width: 768px) {
  .btn {
    &-link {
      font-size: 2rem;
    }

    &-contest {
justify-content: space-evenly !important;
      .btn {
        font-size: 3rem;
        padding: 2.2rem 6.2rem;
img{
  width: 42px;
  height: 42px;
}

      }
    }

    &-outline-dark {
      font-size: 1.6rem !important;
      padding: 10px 36px !important;
    }

    &-fixed {
      padding: 1.6rem 4.4rem;
      display: flex;
      justify-content: center;
      width: 27%;
      margin: 0 auto;
    }

    &-claim-submit {
      padding: 18px 105px;
      font-size: 24px;
      display: flex;
      width: unset;
      margin: 0 auto;
      justify-content: center;
    }

    &-format {
      padding: 24px;
      height: 140px;

      img {
        width: 60px;
      }
    }

    &-sm {
      padding: 0.6rem 1.2rem;
      font-size: 1.2rem;
      border-radius: 2.8rem;
    }

    &-suprise {
      .btn {
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 10px 24px;
        top: 420px !important;
        bottom: unset !important;
        border-radius: 100px;
        width: unset;
        min-width: 17rem;
        justify-content: center;

        img {
          width: 40px;
        }
      }

      .text {
        font-size: 1.6rem;
      }
    }

    &-upload {
      width: 8.5vw;
      height: 8.5vw;

      img {
        height: 60px;
      }

      span {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }

      &-submit {
        padding: 21px 105px;
        max-width: 82%;
        margin: 0 auto;
        font-size: 24px;
      }
    }

    &-alignment {
      display: flex;
      justify-content: end;
    }

    &-category {
      width: 9.5vw;
      height: 99% !important;
      margin-right: 35px;

      &.tickle-active {
        border: 2px solid $primary;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        height: 5vw;
        width: 4vw;
      }

      span {
        font-size: 1.4rem;
      }
    }

    &-ca,
    &-follow,
    &-artist {
      padding: 1.2rem 3.6rem;
      font-size: 1.8rem;
    }

    &-ex-lg {
      padding: 1.8rem 13.8rem;
    }
  }
}

.follow-btn {
  text-align: center;
}

.link-class {
  color: #1985d3;
  cursor: pointer;
}

.surprise-img {
  width: 25px;
  background: white;
  border-radius: 100%;
  border: 2px solid white;

  @media (min-width:767.98px) {
    width: 35px !important;

  }
}

.unique-code-limit {
  .btn-primary:disabled {
    background-color: #898A8D;
  }
}