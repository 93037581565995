.header {
  padding: 1.3rem 0;
  color: $light;
  position: fixed;
  background-color: $light;
  z-index: 99;
  width: 100%;
  top: 0;

  &-profile-pic {
    width: 4.2rem;
    height: 4.2rem;
    display: block;

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  &-desk {
    display: none;
  }

  &-unique-code {
    border-radius: 100px;
    border: 2px solid var(--ios-keyboard-keyboard-shadow-color, #898A8D);

    input {
      border: none;
      outline: none;
      font-size: 1vw;
      padding-left: 8px;
      line-height: 24px;

      margin: 11px 5px;

      @media (min-width:767px) and (max-width:1400px) {
        font-size: 1.4rem;

      }

      &::placeholder {
        opacity: 0.8;
      }

      &:focus,
      &:active,
      &:focus-visible {
        outline: unset;
      }
    }

  }
}

.notification-count {
  width: 11px;
  height: 11px;
  padding: 0 0.5rem;
  font-size: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark;
  line-height: 1.2rem;
  border-radius: 0.6rem;
  background-color: #FFE200;
  border-radius: 100%;
  position: absolute;
  right: 0;
  top: 0;
  font-family: "TCCC-UnityText";

  @media (min-width:400px) {
    font-size: 8px;
    top: 3px;

  }
}

#dropdown-notification+.dropdown-menu-end {
  max-width: 62.4rem;
}

#dropdown-user+.dropdown-menu-end {
  min-width: 18.5rem;

  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      font-size: 2rem;
    }
  }
}

.dropdown-menu-end {
  left: auto;
  right: 0;
}

.unique-code-wrapper {
  border: 2px solid $dark;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
  margin: 3px;

  input {
    font-size: 1.2rem;
    border: none;
    padding-right: 25px;
    background: transparent;
    color: $secondary;
    flex: 1;

    &:focus-visible {
      outline: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .btn {
    border-radius: 10rem;
    margin-left: -40px;
  }
}

@media (min-width: 768px) {
  .notification-count {
    min-width: 14px;
    min-height: 14px;
    border-radius: 0.7rem;
    font-size: 11px;
    line-height: 1.4rem;

  }

  .header {
    &-unique-code {
      display: flex;

      button {
        padding: 12px 32px !important;
        border-radius: 100px;
        margin: 4px;
        text-transform: uppercase;
        font-size: 14px;
        border: none;
      }
    }

    &-ugc {
      // background-color: transparent;
      // .col:first-child {
      //   display: block;
      // }
      // .s-header {
      //   text-align: left;
      // }
      // .search-wrapper {
      //   .search {
      //     width: auto;
      //     input {
      //       padding: 13px 15px 13px 35px;
      //       width: 415px;
      //     }
      //     &-img {
      //       top: 12px;
      //     }
      //   }

      //   button {
      //     background-color: transparent;
      //   }
      // }
    }

    &-desk {
      display: block;

      .container-fluid {
        padding-left: 0 !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .header {
    padding: 1rem 0;
  }
}

.css-1p3m7a8-multiValue {
  display: none !important;
}