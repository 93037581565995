html,
body {
  font-size: 10px;
  font-weight: normal;
  // height: 100%;
  font-family: "Aktiv Grotesk";
  min-height: 100%;
}
#root {
  height: 100%;
  min-height: 100%;
}
img {
  max-width: 100%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.container,
.container-fluid {
  max-width: 1440px !important;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.xy-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

main {
  padding: 5rem 0 8rem;
  margin-bottom: 20px;
  min-height: calc(100vh - 21rem);
}

@media (min-width: 992px) {
  main {
    // padding-top: 10rem;
    // padding-bottom: 0;
    padding: 10rem 15rem 2rem 15rem;
  }
}
@media (min-width: 768px) {
  .container,
  .container-fluid {
    padding: 0 32px;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .col,
  [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
