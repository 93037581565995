.mr_global {
  .modal {
    &-title {
      font-size: 1.2rem;
    }
    .btn-link {
      font-size: 1rem;
    }
  }
  @media (max-width: 400px) {
    .sprite-points {
      &-wrapper {
        h3 {
          font-size: 7px;
        }
      }
    }
  }
  .offcanvas-menu {
    max-width: 80%;
  }
  .wp-wrapper {
    span {
      font-size: 1.2rem;
    }
  }
}
@media (min-width: 767px) {
  .sprite-points-wrapper {
    h3 {
      font-size: 1vw;
    }
    p {
      font-size: 1.2rem;
    }
  }
}
