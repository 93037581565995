// .s is represent here "Section"
.s {
  &-header {
    padding: 3rem 0 1.2rem;
    &.giggle-meter {
      .container-fluid {
        padding-right: 8rem;
      }
      .s-title {
        text-transform: capitalize;
      }
    }

    .container-fluid {
      position: relative;
      padding: 0 10rem 0 2rem;
    }

    .view-more {
      position: absolute;
      right: 1.5rem;
      top: 0;
      cursor: pointer;
      font-size: 1.2rem;
      line-height: normal;
      font-weight: 400;
      color: $dark;

      span {
        &:last-child {
          color: $dark;
          margin-left: 5px;
          @media (min-width: 767.98px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  &-reward-pool {
    .s-title {
      text-transform: capitalize;
    }
  }
  &-creatortool {
    background-color: $light;
    min-height: 163px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h4 {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
  &-over {
    background: url("~/public/assets/img/bgs/contest_over_m.svg") no-repeat;
    height: 100vh;
    background-size: contain;
    h2{
      font-size: 3rem;
    }
    .btn-warning{
      font-size: 12px;
      padding: 12px 24px;
    }
    p{
      font-size: 2rem;
    }
    h4{
      font-size: 6.98vw;
     
    }
    img{
      min-width: 121px ;
    position: relative;
    // top: 148px;
    }
  }
  &-ticklemood {
    background: linear-gradient(
      0deg,
      #fff -21.67%,
      rgba(255, 255, 255, 0) 100%
    );
    padding-bottom: 2.7rem;
    a {
      &:hover {
        color: $dark;
      }
    }
  }
  &-font {
    font-style: italic;
    img {
      width: 20px !important;
      height: 20px;
      min-height: unset !important;
      object-fit: contain;
      margin-left: 2px;
    }
  }
  &-shareJokes,
  &-participate {
    position: relative;
    img {
      // min-height: 140px;
      width: 100%;
      border-radius: 1rem;
    }
    & > div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    h6 {
      font-size: 3vw;
      font-weight: 900;
      color: $primary;
    }
    h4 {
      font-size: 3.4vw;
      margin-bottom: 0.4rem;
    }
    h2 {
      font-size: 2rem;
    }
    &.s-comingsoon {
      img {
        min-height: 125px;
      }
      & > div {
        top: 55%;
        width: 100%;
        left: 50%;
      }
      h2 {
        font-size: 5vw;
        font-weight: 700;
        font-style: italic;
        margin-bottom: 0.4rem;
      }

      h3 {
        font-size: 3.9vw;
        font-weight: 900;
        color: $primary;
        margin-bottom: 0.4rem;
      }
    }
  }
  &-laugh {
    // font-family: "Aktiv Grotesk Trial";
    & > div {
      top: 47%;
      left: 75%;
      width: 30%;
    }
    h3 {
      font-size: 4vw;
      line-height: 4.5vw;
      text-align: center;
    }
    h4 {
      display: none;
    }
    .btn {
      margin-top: 5px;
      font-size: 1.2rem;
      padding: 0.8rem 2rem;
      white-space: nowrap;
    }
  }
  &-comic {
    // font-family: "Aktiv Grotesk Trial";
    h6 {
      color: $dark;
      font-size: 3.18vw;
      line-height: 4vw;
    }
    h5 {
      font-size: 3.18vw;
      line-height: 4vw;
    }
    h3 {
      font-size: 3.3vw;
      color: $primary;
      font-style: italic;
      img {
        height: 3.3vw;
        left: -4px;
        width: 21px;
        object-fit: contain;
        position: relative;
        // z-index: 999;
        // position: relative;
        // left: -10px;
      }
    }
    .btn {
      margin-top: 10px;
      font-size:0.8rem;
      padding: 8px 16px;
    }
  }
  &-over {
    h6 {
      color: $light;
      font-size: 4vw;
    }

  }
  &-fab {
    // font-family: "Aktiv Grotesk Trial";
    h3 {
      color: $primary;
      font-weight: 900;
      font-size: 4vw;
    }
  }
  &-title {
    font-size: 1.6rem;
    line-height: 1.8rem;
    padding-bottom: 0.4rem;
    text-transform: uppercase;
    color: $dark;

    &.md {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &-sub-title {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: normal;
    color: $secondary;

    &.sm {
      font-size: 1rem;
      line-height: 1.2rem;
    }

    &.md {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }
}

.section-comedian {
  // background: linear-gradient(0deg, #fff -21.67%, rgba(255, 255, 255, 0.00) 100%);
  // padding-bottom: 2.7rem;
  .slick-dots {
    bottom: -0.2rem !important;
  }
}

.leaderboard-banner {
  position: relative;
  & > div {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
  }
  h2 {
    font-size: 1.6rem;
  }
  @media (max-width: 370px) {
    h2 {
      font-size: 4.5vw;
    }
    .btn {
      padding: 0.8rem 1.6rem;
    }
  }
}

@media (min-width: 768px) {
  .s {
    &-header {
      padding: 5.8rem 0 2.4rem;
      text-align: left;

      .container-fluid {
        position: relative;
        padding: 0 3.2rem;
      }
      .view-more {
        font-size: 2rem;
      }
      .btn-link {
        top: 50%;
        transform: translateY(-50%);
        padding: 12px 24px 10px 24px;
        font-size: 1.8rem;
        line-height: 1.8rem;
      }
    }
    &-over {
      background-size: cover;
      h6 {
        color: $light;
        font-size: 2vw;
      }
      h4{
        font-size: 2.5vw;
      }
      img{
        width: 250px !important;
  
      }
      p{
        font-size: 1.6rem;
      }
  
  
    }
    &-font {
      img {
        width: 49px !important;
        height: 54px;
        margin-left: 4px;
      }
    }
    &-creatortool {
      padding: 6rem 0;
      h4 {
        font-size: 3.2rem;
      }
    }
    &-fab {
      h3 {
        font-size: 2.94vw;
      }
      h6 {
        font-size: 2.5vw;
      }
    }
    &-title {
      font-size: 3rem;
      line-height: 3.4rem;
      padding-bottom: 1.2rem;

      &.md {
        font-size: 2.4rem;
        line-height: 1;
      }

      &-small {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }

    &-sub-title {
      font-size: 2rem;
      line-height: 2.4rem;

      &.sm {
        font-size: 1.2rem;
        line-height: 1.6;
      }

      &.md {
        font-size: 1.4rem;
        line-height: 1;
      }

      &-small {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }

    &-shareJokes {
      h4 {
        font-size: 2.8vw;
      }
      h2 {
        font-size: 6.6rem;
      }

      &.s-comingsoon {
        img {
          min-height: 125px;
        }

        & > div {
          top: 50%;
        }
        h2 {
          font-size: 4vw;
        }
        h6 {
          font-size: 2.3vw;
        }
        h3 {
          font-size: 3vw;
        }
      }
    }

    &-comic,
    &-participate {
      margin-bottom: 0px;
      img {
        border-radius: 2rem;
      }
      h6 {
        font-size: 2.2vw;
        line-height: 2.7vw;
      }
      h5 {
        line-height: 2.7vw;
        font-size: 2.2vw;
      }

      h3 {
        font-size: 2.71vw;
        color: $primary;
        img {
          height: 2.71vw;
          width: 73px;

          left: -10px;
        }
      }
      .btn {
        margin-top: 20px;
        font-size: 2vw;
        border-radius: 10rem;
        padding: 1.6vw 4.8vw;
      }
    }
    &-laugh {
      margin-bottom: 50px;
      img {
        border-radius: 2rem;
      }

      .btn {
        margin-top: 15px;
        font-size: 2vw;
        border-radius: 10rem;
        padding: 1.6vw 4.8vw;
      }
    }
  }
  .leaderboard-banner {
    .spacing {
      padding: 4.6rem 5.6rem !important;
    }
    h2 {
      font-size: 3rem;
    }
    .btn {
      font-size: 2.8rem;
      padding: 16px 50px;
    }
  }
}

@media (min-width: 2100px) {
  .s {
   
    &-comic {
      h6 {
        font-size: 3vw;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 2vw;
        margin-bottom: 20px;
      }
      .btn {
        font-size: 1.5vw;
      }
    }
  }
}
