.or_global {
  .offcanvas-menu {
    max-width: 80%;
    .dropdown-item {
      font-size: 9px;
    }
  }
  .sprite-points-wrapper {
    h3 {
      font-size: 1.75vw;
    }
  }
  .timer-wrapper {
    span{
      font-size: 8px;
      white-space: nowrap;
    }
  }
  .btn {
    &-artist {
      font-size: 0.7rem;
    }
  }
  .view-more {
    font-size: 8px;
  }
  //   .sprite-points-wrapper {
  //     h3 {
  //       font-size: 1rem;
  //     }
  //   }
  .card {
    &-comedian {
      .card {
        &-text {
          font-size: 8px;
        }
      }
    }
  }

  .s {
    &-title {
      font-size: 2.9vw;
    }

    &-comic {
      .btn {
        font-size: 0.8rem;
      }
    }

    &-laugh {
      .btn {
        font-size: 2vw;
      }
      h3 {
        font-size: 3.5vw;
      }
    }
    &-sub-title {
      font-size: 9px;
    }

    &-shareJokes {
    }
  }

  @media (min-width: 767.98px) {
    .btn {
      &-artist {
        font-size: 1.6rem;
      }
    }
    .sprite-points-wrapper {
      h3 {
        font-size: 1.2vw;
      }
      p {
        font-size: 1vw;
      }
    }
    .s {
      //   &-comingsoon {
      //     h3 {
      //       font-size: 2.4vw;
      //       line-height: 3vw;
      //     }
      //   }
      &-comic {
        .btn {
          font-size: 1.15vw;
          margin-top: 25px;
        }
        h3 {
          font-size: 2.39vw;
        }
      }
      &-title {
        font-size: 2.2vw;
      }
      &-laugh {
        .btn {
          font-size: 1.5vw;
          padding: 1vw 3.4vw;
        }
        h3 {
          font-size: 2.9vw;
          line-height: 4vw;
        }
      }
      &-fab {
        h3 {
          font-size: 1.98vw;
        }
      }
      &-sub-title {
        font-size: 1.6rem;
      }
      &-header {
        .view-more {
          font-size: 18px;
        }
      }
    }
  }
 
  @media (min-width: 1300px) and (max-width: 2000px) {
  .contest-banner img {
  margin-left: 30px;
  }
}
@media (max-width: 400px) {
  .timer-static h4 {
      font-size: 3.01vw;
  }
}
}
