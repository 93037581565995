// .comic{
//     background: url("~/public/assets/img/bgs/comic-banner.svg") center center no-repeat;
// }

.content-wrapper {
  display: flex;
  padding: 16px 0;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.cash{
  &-para{
    font-size: 7.5px;
  }
}
.or-copy-link {
  max-width: 280px;
  margin: 0 auto;
  border-radius: 8px;

  input {
    font-size: 14px;
    border: 2px dashed #009639;
    padding: 7px 14px;
    font-weight: 700;
    border-radius: 8px 0 0 8px;


    &:focus-visible,
    &:focus {
      outline: none;
    }
  }

  .copy-btn {
    border-radius: 0px 8px 8px 0px;
    white-space: nowrap;
    font-size: 10px;
    min-width: 85px;
    padding: 9px 13px;
    font-weight: 600;
    border: 1px solid transparent;
  }
}

.inner-container {
  .wallet-header {
    h3 {
      font-size: 2.8rem;
    }

    img {
      width: 21px;
      height: 26px;
      margin-top: 2px;
    }
  }
}

.wallet,
.contest {
  

  .card {
    &-title {
      font-weight: 700;
      display: flex;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
    @media (max-width:367px) {
      font-size: 2.89vw;
      
    }
    .info-icon{
      margin-left: 5px  !important;
    }
    }

    &-reward {
      .btn {
        padding: 12px 36px;
        font-size: 12px;
        margin-top: 5px;
        white-space: nowrap;
      }

      .claim {
        &-time {
          font-weight: 700;
          font-size: 7px;

          span {
            color: $primary;
          }
        }
      }
    }
  }
}

.leaderboard-banner {
  background: url("~/public/assets/img/bgs/participate-banner.svg") center center no-repeat;
  border-radius: 1rem;
  background-size: cover;

  h2 {
    color: $light;
  }

  .spacing {
    padding: 12px 16px;
  }
}

.contest-winner {
  background: transparent;

  .spacing {
    padding: 0 !important;
  }

  h2 {
    color: $dark;
  }
}
.pagination-container {
  cursor: pointer;
  font-size: 1.3rem;

  button {
    all: unset;
  }
}

.current-page {
  border-radius: 2px;
  background: $dark;
  color: #fff;
  text-align: center;
  width: 2rem;
}
@media (min-width: 767.98px) {
 
  .pagination-container{
    font-size: 2rem;
  }
  .current-page {
  width: 2.6rem;
  }
  .cash{
    &-para{
      font-size: 1.6rem;
    }
  }
  .or-copy-link {
    padding: 14px ;
    width: 300px;
  
    .copy-btn{
      font-size: 1.2rem;
      min-width: 9.6rem;
    }

    input {
      border: 2px dashed $primary;
      padding: 5px 7px;
      font-size: 1.6rem;

      &:focus-visible,
      &:focus {
        outline: none;
      }
    }

   
  }

  .wallet,
  .contest {
    .info-icon {
      width: 26px;
    
    }

    .card {
      &-title {
        font-size: 2.8rem;
        line-height: normal;
      }

      &-reward {
        .btn {
          padding: 12px 30px;
          font-size: 1.6rem;
        }

        .claim {
          &-time {
            font-size: 12px;
          }
        }
      }

      @media (min-width: 768px) and (max-width: 1300px) {
        &-title {
          font-size: 1.5vw;
        }

        &-text {
          font-size: 1.2rem;
          line-height: 1.4rem;
        }

        &-reward {
          .btn {
            padding: 12px 20px;
            font-size: 1.4rem;
          }
        }

        .info-icon {
          width: 12px;
          margin-left: 7px;
          top: 35px;
          // right: 3px;
        }
      }
    }
  }

  .inner-container {
    width: 99.19vw;
    margin-left: -50vw;
    position: relative;
    left: 50%;

    .wallet-header {
      padding: 0rem 15.7rem 0rem 15.7rem;

      h2 {
        font-size: 3rem;
      }

      h3 {
        font-size: 6.4rem;
      }

      img {
        width: 47px;
        height: 47px;
        margin-top: 0;
      }
    }
  }

  .leaderboard-banner {
    border-radius: 2rem;
  }
}

.mobile-header {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 12rem);
}

.wallet {
  &-pool {
    .info-icon {
      
      top: 26px;
      }
  
    .share-link {
      text-decoration: underline;
      font-weight: 700;
      font-size: 9px;
      padding-top: 10px;
    }

    .card {
      &-title {
        margin-top: 20px;
        font-size: 2rem;
      }

      &-text {
        font-size: 16px;
        line-height: 18px;
        // @media (max-width:370px) {
        //   font-size: 2.4vw;
        // }
      }

      @media (max-width:410px) {
        &-title {
          font-size: 5vw;
        }

        &-text {
          font-size: 3.58vw;
        }

      }
    }

    @media (min-width:767.98px) {
      .card {

        &-reward {
          max-width: 507px;
          margin: 0 auto;
        }
&-title{
  font-size: 2.8rem;
}
        &-text {
          font-size: 2rem;
          line-height: 2.4rem;
          min-height: 52px;
        }
      }
    }
  }

}

.button-wrapper {
  justify-content: space-evenly;

  .btn {
    padding: 8px 12px;
    font-size: 12px;
    margin-top: 5px;
    white-space: nowrap;
  }
}

.text-underline {
  text-decoration: underline;
  margin-top: 7px;
  font-size: 1.2rem;
  @media (min-width:767.98px) {
    font-size: 1.6rem;
  }
}



.modal {
  &-dialog {
    .card-reward {
      .card {
        &-title {
          margin-top: 10px;
          font-size: 2rem;
        }

        &-text {
          font-size: 16px;
          line-height: 18px;

        }

        @media (max-width:370px) {
          &-title {
            font-size: 5vw;
          }

          &-text {
            font-size: 3.54vw;
          }

        }
      }
    }
  }
}
p.redeem-date{
  font-size: 1.2rem !important;
  font-weight: 700;
}

