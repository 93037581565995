.reward-para{
  span{
    font-weight: bold;
  }
}
.card {
  border: unset;

  &-body {
    padding: 0.4rem 1rem 1rem;
    position: relative;
  }
  &-heading{
    max-width: 63vw;
  }
  &-profile-joke {
    .view-more {
      span {
        &:last-child {
          color: $primary;
          margin-left: 0;
        }
      }
    }
  }
  &-wrapper {
    padding: 1.2rem 0;
    border-bottom: 1px solid $grey;
    &:last-child {
      border-bottom: unset;
      padding-bottom: 0;
    }
    &:first-child {
      padding-top: 0;
    }
    a {
      text-decoration: underline;
    }
    h4 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1.2rem;
    }
    .btn-primary {
      padding: 8px 14px;
      font-size: 12px;
    }
  }
  &-report {
    span {
      font-size: 12px;
    }
  }
  &-details {
    background: url("~/public/assets/img/bg-profile.svg") center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-tickle-wrapper {
    gap: 25px;
    margin-top: 2rem;
  }

  &-comedian {
    background-color: transparent;
    align-items: center;

    .card {
      &-body {
        padding: 0;
        text-align: center;

        h4 {
          @media (max-width: 400px) {
            font-size: 2.56vw;
            min-height: 20px;
          }
        }
      }
    }

    img {
      border-radius: 100%;
      width: 21vw;
      height: 21vw;
      cursor: pointer;
      background-color: $primary;
      border: 2px solid $light;
      object-fit: contain;
    }
  }

  &-tickle {
    display: flex;
    flex-direction: column;
    align-items: center;
    // img {
    //   width: 80px;
    //   height: 80px;
    //   border-radius: 100%;
    //   background-color: $light;
    //   padding: 10px;
    //   margin: 0 auto;
    // }
    & > div {
      border-radius: 100px;
      background: $light;
      border: 2px solid $light;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
      width: 19vw;
      height: 19vw;
      padding: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      img {
        width: 14vw;
        height: 14vw;
      }
    }

    &:nth-child(3) {
      img {
        margin-bottom: -15px;
      }
    }

    h6 {
      font-size: 1.2rem;
      font-weight: 400;
      margin-top: 1rem;
    }
  }

  &-contest-horizontal {
    background: #fff;
    border-radius: 20px;

    .card-img-top {
      border-radius: 20px 0 0 20px;
    }
  }

  &-title {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 0.2rem;
    font-weight: 700;
  }

  &-text {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0.7rem;
    color: #313131;
    font-weight: normal;
  }

  &-tiles {
    .btn {
      &-link {
        color: $dark;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
      }
    }

    .card {
      &-body {
        padding: 1rem 0 0 0;
      }

      &-title {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-bottom: 0.3rem;
      }

      &-text {
        font-size: 1rem;
        line-height: 1.4rem;
        color: $secondary;
      }
    }
  }

  &-horizontal {
    background-color: transparent;
    border-radius: unset;
  }

  &-joke {
    padding: 0.9rem;
    min-width: 31rem;
    width: 100%;

    .overlay {
      max-height: 76vw;
      min-height: 76vw;
      height: 100%;
      position: relative;
      padding: 0;
    }

    .card {
      &-header {
        padding: 0 0 16px;
      }

      &-body {
        padding: 0;
      }

      &-footer {
        background-color: transparent;
        border-top: none;
        padding: 16px 0 0;

        .icon {
          &-block {
            bottom: 0;
          }
        }
      }
    }
  }

  .giggle-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      max-width: 265px;
    }
  }

  &-joke.giggle {
    background-image: url("~/public/assets/img/giggle-card-bg.webp");
    min-width: unset;
    margin-bottom: 20px;

    .img {
      border-radius: 5px 5px 0 0;
      max-height: 76vw;
      min-height: 76vw;

      &-card {
        // max-height: unset;
        max-height: 76vw;
        min-height: 76vw;
        position: absolute;
        object-fit: cover;
        object-position: top;
      }
    }

    .footer-reaction {
      padding: 0 8px 0px 8px !important;
      margin-bottom: 0 !important;
    }

    .card {
      &-header {
        border: none;
      }

      &-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: unset !important;

        .img-block {
          // height: 120px;
        }
      }

      &-footer {
        border-radius: 0px 0px 5px 5px;
        background: #fff;
        padding: 0;
      }
    }

    h4 {
      font-size: 12px;
    }
  }

  &-lg {
    .card {
      &-title {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-bottom: 0.8rem;
        font-weight: 700;
      }

      &-text {
        font-size: 1.2rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        color: $dark;
      }
    }
  }

  &-top-img-wrapper {
    position: relative;
  }

  &-disabled {
    opacity: 0.5;
  }

  &-disabled-not-btn {
    .car-top-img-wrapper,
    .card-title,
    .card-text,
    .points-block {
      opacity: 0.5;
    }
  }

  &-reward {
    padding: 0.5rem;
    border-radius: 0.5rem;

    .card-body {
      padding-bottom: 1rem;
    }

    .card-img-top {
      border-radius: 0.5rem;
    }

    .points-box {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  &-time-stamp {
    font-size: 0.8rem;
    color: #838383;
  }
}

// Image blocks & cards
.img {
  &-block {
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    // max-width: 179px;
    background-color: #ffffff;
    width: 100%;
    object-fit: contain;

    .icon-info {
      position: absolute;
      right: 6px;
      top: 9px;
    }

    .new-badge {
      position: absolute;
      left: 0;
      top: 3px;
    }

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    &-overlay {
      &::before {
        content: "";
        background-color: $dark;
        opacity: 0.7;
        // max-height: 173px;
        height: 100%;
        z-index: 1;
      }
    }

    // &-gradient {
    //   &::before {
    //     content: '';
    //     background: rgb(0, 0, 0);
    //     background: linear-gradient(0deg,
    //         rgba(0, 0, 0, 1) 0%,
    //         rgba(0, 0, 0, 0) 100%);
    //   }
    // }

    &-sm {
      border-radius: 0.8rem;
    }
  }

  &-card {
    border-radius: 1rem;
    width: 100%;

    &-full-img {
      border-radius: 0;
    }

    &-sm {
      border-radius: 0.8rem;
    }
  }
}

// Social purpose icons
.icon {
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &-block {
    // position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 5px;
    display: flex;
    justify-content: space-between;

    .views-link {
      span {
        font-size: 1rem;
      }
    }

    .icon {
      color: $dark;
      font-size: 0.6rem;
      display: flex;
      align-items: center;
    }

    img {
      width: 20px;
      height: 20px;
    }

    &-lg {
      .icon {
        &-play {
          font-size: 6.3rem;
        }
      }
    }

    &-top {
      bottom: unset;
      top: 1rem;
    }
  }

  &-num {
    font-weight: normal !important;
    display: inline-block;
    // padding-left: 0.2rem;
    font-size: 1rem;
    margin-top: 1px;
  }

  &-play {
    color: $primary;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 500;
    position: absolute;
    left: 50%;
    z-index: 1;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 4rem;
    width: 100%;

    &:hover {
      color: $primary;
    }
  }

  &-back,
  &-close {
    font-size: 2rem;
    cursor: pointer;
  }

  &-notifi {
    font-size: 3rem;
    font-weight: 500;
    color: $dark;

    &:hover {
      color: $dark;
    }
  }
}

.s-giggle {
  .icon {
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &-block {
      position: absolute;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
      display: flex;
      justify-content: space-between;

      .views-link {
        span {
          font-size: 1rem;
        }
      }

      .icon {
        color: $dark;
        font-size: 0.6rem;
        display: flex;
        align-items: center;
      }

      img {
        width: 14px;
        height: 14px;
      }

      &-lg {
        .icon {
          &-play {
            font-size: 6.3rem;
          }
        }
      }

      &-top {
        bottom: unset;
        top: 1rem;
      }
    }

    &-num {
      font-family: "TCCC-UnityText" !important;
      font-weight: normal !important;
      display: inline-block;
      padding-left: 0.2rem;
      margin-top: 3px;
    }

    &-play {
      color: $primary;
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-weight: 500;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      max-width: 104px;
      width: 100%;

      &:hover {
        color: $primary;
      }
    }

    &-back,
    &-close {
      font-size: 2rem;
      cursor: pointer;
    }

    &-notifi {
      font-size: 3rem;
      font-weight: 500;
      color: $dark;

      &:hover {
        color: $dark;
      }
    }
  }
}

@media (min-width: 768px) {
  .card {
    &-body {
      padding: 1rem 3rem 3.2rem;
    }
    &-wrapper {
      padding: 2rem 0;
      border-bottom: 1px solid $grey;
      img {
        width: 94px;
      }
      h4 {
        font-size: 2.8rem;
      }
      p {
        font-size: 2rem;
      }
      .btn-primary {
        padding: 16px 40px;
        font-size: 20px;
      }
    }
    &-heading {
      width: calc(100% - 67px);
      height: 44px;
    }
    &-details {
      border-radius: 2rem;
    }
    &-tickle {
      & > div {
        width: 9vw;
        height: 9vw;
        img {
          width: 5vw;
          height: 5vw;
        }
      }
      h6 {
        font-size: 1.6rem;
      }
    }
    &-joke.giggle {
      .img {
        min-height: 24vw;
        max-height: 24vw;
        object-fit: cover;
        object-position: left;
      }
    }

    &-joke {
      padding: 1.6rem;

      .overlay {
        max-height: 24vw;
        height: 100%;
        padding: 0;
        position: relative;
        min-height: 24vw;
        // padding-bottom: 100%;
        @media (min-width: 992px) and (max-width: 1280px) {
          // min-height: 230px;
        }
      }
    }

    &-title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 0.6rem;
      font-weight: 500;
    }

    &-comedian {
      .card {
        &-body {
          padding: 0;

          h4 {
            font-size: 2rem;
          }
        }
      }

      img {
        width: 9vw;
        height: 9vw;
      }

      @media (max-width: 1600px) and (min-width: 991px) {
        .card {
          &-body {
            h4 {
              font-size: 1.1vw;

              @media (max-width: 1320px) {
                margin: 0 !important;
                min-height: 2.5vw;
              }
            }
          }

          &-text {
            font-size: 1vw;
          }
        }
      }
    }

    &-text {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 2rem;
    }

    &-joke.giggle {
      .card {
        &-footer {
          // padding:12px 0;
          .icon {
            &-block {
              bottom: 6px;
            }
          }
        }
      }

      h4 {
        font-size: 12px;
      }

      .img-card {
        max-height: 24vw;
        position: absolute;
        object-fit: cover;
        object-position: top;
        min-height: 24vw;
      }
    }

    &-tiles {
      .btn {
        &-link {
          font-size: 1.6rem;
        }
      }

      .card {
        &-body {
          padding: 1.8rem 0 0 0;
        }

        &-title {
          font-size: 2rem;
          line-height: 1;
          margin-bottom: 0.6rem;
        }

        &-text {
          font-size: 1.6rem;
          line-height: 1;
        }
      }
    }

    &-horizontal {
      &-full-img {
        border-radius: 2rem;
      }
    }

    &-lg {
      .card {
        &-title {
          font-size: 2.8rem;
          line-height: 3.2rem;
          margin-bottom: 1.8rem;
        }

        &-text {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }
      }
    }

    &-reward {
      .card {
        &-body {
          @media (min-width: 767.87px) and (max-width: 1300px) {
            padding: 1rem;
          }
        }
      }
      .points-box {
        right: 1.3rem;
        top: 1.6rem;
      }
    }

    &-time-stamp {
      font-size: 1rem;
    }
  }

  // Image blocks & cards
  .img {
    &-block {
      border-radius: 0.8rem;

      &-overlay {
        &::before {
          max-height: 24vw;
          height: 100%;
          z-index: 1;
          min-height: 24vw;
        }
      }

      .icon-info {
        right: 12px;
        top: 15px;
      }

      .new-badge {
        top: 9px;
      }

      &-sm {
        border-radius: 1.5rem;
      }
    }

    &-card {
      border-radius: 1rem;

      &-full-img {
        border-radius: 0;
      }

      &-sm {
        border-radius: 1.5rem;
      }
    }
  }

  // Social purpose icons
  .icon {
    &-block {
      left: 1.5rem;
      right: 1.5rem;
      // bottom: 1.7rem;

      .icon {
        font-size: 1.5rem;
      }

      img {
        width: 20px;
        height: 20px;
      }

      .views-link {
        span {
          font-size: 1.6rem;
        }
      }

      &-lg {
        left: 5rem;
        right: 5rem;
        bottom: 2rem;

        .icon {
          font-size: 3.7rem;

          &-num {
            font-size: 3rem;
            padding-left: 0.5rem;
          }

          &-play {
            font-size: 8rem;
            z-index: 1;
          }
        }
      }

      &-top {
        bottom: unset;
        top: 2rem;
      }
    }

    &-play {
      font-size: 2rem;
      line-height: 2.6rem;
      max-width: 8rem;
    }

    &-num {
      font-size: 1.2rem;
    }

    &-like {
      font-size: 3rem;
    }
  }

  .s-giggle {
    .icon {
      &-block {
        left: 1.5rem;
        right: 1.5rem;
        bottom: 1.7rem;

        .icon {
          font-size: 1.5rem;
        }

        .views-link {
          span {
            font-size: 1.6rem;
          }
        }

        &-lg {
          left: 5rem;
          right: 5rem;
          bottom: 2rem;

          .icon {
            font-size: 3.7rem;

            &-num {
              font-size: 3rem;
              padding-left: 0.5rem;
            }

            &-play {
              font-size: 8rem;
            }
          }
        }

        &-top {
          bottom: unset;
          top: 2rem;
        }
      }

      &-play {
        font-size: 2rem;
        line-height: 2.6rem;
        max-width: 194px;
      }

      &-num {
        font-size: 1.2rem;
      }

      &-like {
        font-size: 3rem;
      }
    }
  }
}

@media (min-width: 992px) {
  .img {
    &-card {
      &-full-img {
        border-radius: 2rem;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .card {
    &-report {
      span {
        font-size: 12px;
      }
      img {
        width: 12px;
      }
    }
    &-contest-horizontal {
      .card-img-top {
        height: 100%;
      }
    }

    &-xl {
      .card {
        &-title {
          font-size: 3.2rem;
          line-height: 4rem;
          margin-bottom: 0.8rem;
        }

        &-text {
          font-size: 2.8rem;
          line-height: 3.2rem;
        }
      }
    }
  }
}

.section {
  &-comedian {
    .s-sub-title {
      font-size: 1.2rem;
      color: $dark;
    }

    img {
      border: 5px solid $light;
      background-color: $light;
      border-radius: 100%;
      width: 100px;
      height: 100px;
    }

    .card {
      width: 25vw;
      background-color: transparent;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &-title {
        font-size: 1rem;
        font-weight: 700;
      }

      &-body {
        padding: 1.3rem 0 0;
      }

      p {
        font-size: 0.8rem;
        font-weight: 400;
        color: $dark;
      }
    }
  }
}

@media (min-width: 768px) {
  .section {
    &-comedian {
      .slick-slide > div {
        margin: 0 4px;
      }

      .s-sub-title {
        font-size: 1.2rem;
      }

      // img {
      //   height: 165px;
      // }

      .card {
        &-title {
          font-size: 2rem;
        }

        &-body {
          padding: 1.6rem;
        }

        p {
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media (min-width: 767.98px) and (max-width: 1100px) {
  .card-comedian {
    h4 {
      min-height: 32px;
      font-size: 1.2vw !important;
    }

    .card-text {
      font-size: 0.9vw;
      line-height: 1.1vw;
    }
  }
}

.tickle-active {
  border: 2px solid #009639;
}

.text-post {
  background-image: url(../../../../public/assets/img/text-background.svg);
  min-height: 24vw;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-position: center;
  width: 100%;
  display: block;
  @media (max-width: 767.98px) {
    min-height: 76vw;
    background-size: cover;
  }
  @media (min-width:420px) and (max-width:500px) {
background-size: cover;
   min-height: 375px;
   max-height: 375px;
   }
}

.text-post p {
  // padding: 112px 14px 0 120px;
  color: white;
  // word-break: break-word;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // position: relative;
  // top: 56px;
  // left: 6vw;
  // width: 13vw;
  // -webkit-line-clamp: 12;
  // -webkit-box-orient: vertical;
  width: 100%;
  padding: 0 7px 0 7vw;
  justify-content: center;
  display: flex;
  height: 23vw;
  position: relative;
  top: 6px;
  align-items: center;


  @media (max-width: 767.98px) {
    width: 100%;
    padding: 0 13px 0 28vw;
    display: flex;
    height: 76vw;
    margin-bottom: 0;
    position: relative;
    top: 6px;
    align-items: center;
   
  }
  @media (min-width:420px) and (max-width:767px) {
    padding: 0 13px 0 155px ;
    height: 375px;
   }
}
.joke-img {
  min-height: 24vw;
  max-height: 24vw;
  width: 100%;
  @media (max-width: 767.98px) {
    max-height: 280px;
    min-height: 76vw;
  }
}
.joke-img-card {
  min-height: 24vw;
  max-height: auto;
  width: 100%;
  @media (max-width: 767.98px) {
    max-height: auto;
    min-height: 76vw;
  }
}
