.mai_global {
  .modal {
    &-title {
      font-size: 1.2rem;
    }
    .btn-link {
      font-size: 1rem;
    }
  }
  .s {
    &-sub-title {
      font-size: 1rem;
    }
    &-comic {
      .btn {
        font-size: 6px;
      }
    }
  }
  @media (min-width: 767.98px) {
    .modal {
      &-title {
        font-size: 1.6rem;
      }
    }
    .s {
      &-sub-title {
        font-size: 1.6rem;
      }
      &-comic {
        .btn {
          font-size: 2.4rem;
        }
      }
      &-fab {
        h3 {
          font-size: 3.4vw;
        }
      }
    }
  }
}
