.highlighted_mask_disable {
  opacity: 0.7;
  color: rgb(0, 0, 0);
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  position: fixed;
  z-index: 99999;
  pointer-events: none;
  #mask-main {
    display: none;
  }
}
.reactour__helper.reactour__helper--is-open {
  border-radius: 200px;
  background: #ffe200;
  width: 185px;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactour__helper--is-open {
  &:has(.change_language) {
    transform: none;
    right: -19px !important;
    left: auto !important;
    top: -51px;
    z-index: 99999;
    padding-left: 14px;
    width: 226px;
    height: 206px;
    img {
      float: right;
    }
  }
  &:has(.change-lang-d) {
    transform: none;
    right: 145px;
    left: auto !important;
    top: -82px;
    z-index: 99999;
    padding-left: 14px;
    width: 290px;
    height: 257px;

    img {
      float: right;
    }
  }
  &:has(.tour-explore) {
    transform: none;
    left: 305px !important;
    width: 282px;
    height: 269px;
    top: -94px;

    img {
      float: right;
    }
  }
  &:has(.tour-contest) {
    transform: none;
    left: 450px !important;
    width: 282px;
    height: 269px;
    top: -94px;
  }
  &:has(.avatar_icon) {
    transform: none;
    right: 94px;
    left: auto !important;
    top: -86px;
    z-index: 99999;
    padding-left: 14px;
    width: 272px;
    height: 272px;
    .avatar_icon {
      img {
        float: right;
      }
    }
  }
  &:has(.tour-pick) {
    border-radius: 200px;
    background: #ffe200;
    width: 160px;
    transform: none;
    height: 160px;
    left: -26px;
    top: 312px;
    @media (min-width: 991px) {
      height: 230px;
      width: 230px;
      top: 420px;
    }
  }
  &:has(.tour-comedian) {
    border-radius: 200px;
    background: #ffe200;
    width: 144px;
    transform: none;
    height: 151px;
    left: -26px;
    top: 550px;
    @media (min-width: 991px) {
      height: 230px;
      width: 240px;
      top: 300px;
    }
  }
  &:has(.tour-tab) {
    border-radius: 200px;
    background: #ffe200;
    width: 144px;
    transform: none;
    height: 151px;
    left: -26px;
    top: 340px;
    @media (min-width: 991px) {
      border-radius: 200px;
      background: #ffe200;
      width: 200px;
      transform: none;
      height: 219px;
      left: -26px;
      top: 315px;
    }
  }
  &:has(.home) {
    transform: none;
    left: -40px !important;
    bottom: -31px;
    top: unset !important;
    z-index: 99999;
    padding-left: 37px;
    width: 163px;
    height: 163px;
    .home {
      display: flex;
      flex-direction: column-reverse;
    }
    img {
      width: 50px;
    }
  }
  &:has(.contest) {
    transform: none;
    left: 75px !important;
    bottom: -31px;
    top: unset !important;
    z-index: 99999;
    padding-left: 37px;
    padding-right: 42px;
    width: 171px;
    height: 171px;

    .contest {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    .tour-text {
      text-align: center !important;
    }
    img {
      width: 50px;
    }
  }
  &:has(.tour-unique-code) {
    transform: none;
    left: 3px !important;
    bottom: -21px;
    top: unset !important;
    z-index: 99999;
    padding-left: 37px;
    padding-right: 42px;
    width: 188px;
    height: 185px;
    .tour-text {
      text-align: center !important;
    }
    img {
      width: 87px;
      height: 87px;
    }
  }
  &:has(.profile-tour) {
    transform: none;
    left: unset !important;
    right: -17px !important;
    bottom: -35px;
    top: unset !important;
    z-index: 99999;
    padding-left: 31px;
    width: 180px;
    height: 179px;

    .profile-tour {
      display: flex;
      flex-direction: column-reverse;
    }

    img {
      width: 50px;
    }
  }
  &:has(.navbar-toggler) {
    transform: none;
    left: -28px !important;
    top: -25px;
    padding-left: 45px;
    z-index: 99999;
  }
}

.tour-comedian {
  width: 80px;
  @media (min-width: 991px) {
    width: 150px;
  }
}
.tour-pick {
  width: 100px;
  @media (min-width: 992px) {
    width: 150px;
  }
}

.tour-tab {
  width: 80px;
  @media (min-width: 991px) {
    width: 120px;
  }
}
.tour-unique-code {
  .tour-text {
    width: 140px;
  }
}

.button.reactour__dot--is-active,
button.reactour__dot {
  display: none !important;
}
.ghknmB {
  display: none !important;
}
.tour-text {
  color: #000;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tour-btn-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  div {
    margin-right: 1.12rem;
  }
  .tour-btn-link {
    color: $link-color;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.left-wrapper {
  width: 1px !important;
  @media (min-width: 991px) {
    position: relative;
    left: -250px;
  }
}

// contest page tourguide
.contest_tour.reactour__helper.reactour__helper--is-open {
  width: 160px;
  height: 160px;

  // &:has(.tour-reward) {
  //   left: -33px;
  //   @media (min-width: 767.98px) {
  //     left: auto;
  //   }
  // }
  &::after {
    display: block;
    content: "";
    width: 15px;
    height: 24px;
    background: url("~/public/assets/img/tour-guide/tour-arrow.svg") center
      center no-repeat;
    position: absolute;
    top: -15px;
    left: calc(50%);
    rotate: -85deg;
  }
  @media (min-width: 767.98px) {
    width: 180px;
    height: 180px;
  }
}
.contest_tour {
  .tour {
    &-heading {
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-weight: 700;
      text-align: center;
    }
    &-text {
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-align: center;
    }
    @media (min-width: 767.98px) {
      &-heading {
        font-size: 1.8rem;
        line-height: 1.1;
      }
      &-text {
        font-size: 1.4rem;
        line-height: 1.1;
      }
    }
  }
  @media (min-width: 767.98px) {
    width: 180px;
    height: 180px;
  }
}
.contest_tour {
  .tour {
    &-heading {
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-weight: 700;
      text-align: center;
    }
    &-text {
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-align: center;
    }
    @media (min-width: 767.98px) {
      &-heading {
        font-size: 1.8rem;
        line-height: 1.1;
      }
      &-text {
        font-size: 1.4rem;
        line-height: 1.1;
      }
    }
  }
}

// // Joyride onboarding for contest page start here
.react-joyride__overlay {
  // position: fixed !important;
  // background-color: rgba(0, 0, 0, 0.7) !important;
}
.react-joyride__spotlight {
  // border-radius: 10px !important;
  // background-color: white !important;
}
button[data-test-id] {
  display: none;
}
.__floater__body {
  border-radius: 100% !important;
  width: 160px !important;
  height: 160px !important;
  background: #ffe200;
  @media (min-width: 767.98px) {
    width: 180px !important;
    height: 180px !important;
  }
  & > div {
    border-radius: 100% !important;
    width: 160px !important;
    height: 160px !important;
    background: transparent !important;
    @media (min-width: 767.98px) {
      width: 180px !important;
      height: 180px !important;
    }
    &:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .tour {
    &-heading {
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-weight: 700;
      text-align: center;
    }
    &-text {
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-align: center;
    }
    @media (min-width: 767.98px) {
      &-heading {
        font-size: 1.8rem;
        line-height: 1.1;
      }
      &-text {
        font-size: 1.4rem;
        line-height: 1.1;
      }
    }
  }
}
.__floater__arrow {
  span {
    top: 4px;
  }
  svg {
    polygon {
      fill: #ffe200;
    }
  }
}
.__floater {
  // margin-top: -25px;
}
.__floater[x-placement="top-start"],
.__floater[x-placement="top"] {
  .__floater__arrow {
    span {
      left: 50% !important;
      bottom: 2px !important;
      transform: translateX(-66%) rotate(0deg) !important;
      top: auto !important;
    }
  }
}
.__floater[x-placement="bottom-start"] {
  .__floater__arrow {
    span {
      left: 50% !important;
      transform: translateX(-66%) rotate(4deg) !important;
      top: 2px !important;
    }
  }
}
