.title {
  font-size: 3.5vw;
  white-space: nowrap;
}

.artist {
  .card {
    &-tiles {
      p {
        font-size: 8px;
      }
    }
  }
  h3 {
    @media (max-width: 400px) {
      font-size: 2.67vw;
    }
  }

  &-header {
    margin-top: 0 !important;
  }

  &-detail {
    .card-joke.giggle .card-body{
 max-height: 40vw;
min-height: 40vw;
@media (min-width:767.98px) {
  max-height: 24vw;
  min-height: 24vw;
  
}
@media (min-width:767.98px) {
  max-height: 24vw;
  min-height: 24vw;
  
}
img{
  max-height: 40vw;
    min-height: 40vw;
    @media (min-width:767.98px) {
      max-height: 24vw;
      min-height: 24vw;
      
    }
}
.img-block-overlay{
  max-height: 40vw ;
  min-height: 40vw ;
  @media (min-width:767.98px) {
    max-height: 24vw;
    min-height: 24vw;
    
  }
  ::before{
    max-height: 40vw ;
    min-height: 40vw ;
    @media (min-width:767.98px) {
      max-height: 24vw;
      min-height: 24vw;
      
    }
  }
}
.card-joke .overlay{
  max-height: 40vw;
    min-height: 40vw;
    @media (min-width:767.98px) {
      max-height: 20vw;
      min-height: 24vw;
      
    }
    &::before{
      max-height: 40vw ;
    min-height: 40vw ;
    @media (min-width:767.98px) {
      max-height: 24vw;
      min-height: 24vw;
      
    }
    }
}
    }
    .card {
      &-report {
        display: none !important;
      } 
      &-horizontal {
        background: url("~/public/assets/img/details-bg-m.svg") center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 1rem;

        .img {
          &-card {
            width: 154px;
            margin: 0 auto;
            height: 148px;
            object-fit: contain;
            margin-top: 40px;
          }
        }

        .card-body {
          text-align: left !important;
        }
      }
    }
  }

  .img {
    &-card {
      border-radius: 1rem;
      background: $primary;
      border: 5px solid #fff;
    }
  }

  @media (min-width: 767px) {
    .title {
      font-size: 1.6rem;
    }

    .artist {
      &-details {
        .card {
          &-horizontal {
            background: url("~/public/assets/img/details-bg-d.svg") center
              center;
          }
        }
      }
    }

    .card-tiles {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 1.6rem;
      }
    }

    h3 {
      font-size: 2rem;
    }

    &-detail {
      .card {
        &-horizontal {
          flex-direction: row;
          justify-content: center;
          border-radius: 2rem;

          .img {
            &-card {
              width: 390px;
              height: 372px;
              margin-top: auto;
              object-fit: contain;
            }
          }

          .card-body {
            text-align: left !important;
          }
        }
      }
    }
  }
}

.shareJokes {
  background: url("~/public/assets/img/sharejokes-bg.svg") no-repeat;
  background-size: cover;

  .wrapper {
    h4 {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 21px;
      font-weight: bolder;
      font-style: italic;
    }

    a {
      position: absolute;
      font-size: 8px;
      bottom: 7px;
      right: 16px;
      color: #000;
    }
  }
}

.artist-icon-num {
  text-align: center;
}

.react-emoji-footer {
  margin-top: 0.8rem;
  width: 1.6rem !important;
  height: 1.6rem !important;
}

.locked-joke {
  position: absolute;
  width: 40px;
}
.icon-type {
  width: 45px;
  position: absolute;
  top: 6px;
  right: 6px;
}

.title-icon {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important;
}

.title {
  font-size: 7px;
}

.subtitle {
  font-size: 9px;
}
@media (min-width: 767px) and (max-width: 1200px) {
  .title {
    font-size: 1.2rem !important;
  }
  .subtitle {
    font-size: 1rem !important;
  }
}
@media (min-width: 767.98px) {
  .locked-joke {
    width: 70px;
  }

  .title {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .icon-type {
    width: 40px;
    top: 16px;
    right: 16px;
  }

  .title-icon {
    width: 17px !important;
    min-width: 17px !important;
    max-width: 17px !important;
  }
  .subtitle {
    font-size: 1.2rem;
  }
  .react-emoji-footer {
    margin-top: 1.4rem;
  }
}
.artist-page {
  .page {
    &-header {
      .col-9 {
        position: unset;
      }
      @media (max-width: 5760px) {
        .form-select-language {
          font-size: 1rem;
        }
      }

      .icon-back {
        top: 20px;
      }
      &-logo {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        margin-left: 0 !important;
        left: 50%;
      }
    }
  }
  .card {
    .giggle {
      &-text {
        .title {
          max-width: 80%;
          font-size: 1rem;
        }
      }
    }
  }
}
