.giggle-carsouel {
  .wrapper {
    max-width: 60%;
    min-height: 38.8px;
    align-items: center;
    @media (max-width: 767.98px) {
      max-width: 70%;
    }
  }
  .title {
    line-height: 1.5;
  }
  ul.slick-dots {
    bottom: 2px !important;
  }
}
.s {
  &-reward-pool {
    margin-bottom: 30px;
    .s-header {
      padding-top: 0px;
      a {
        position: absolute;
        right: 1.5rem;
        top: 0;
        cursor: pointer;
        // font-size: 1.2rem;
        line-height: normal;
        font-weight: 400;
        color: #000000;
      }
    }
    .slick {
      &-dots {
        bottom: -17px !important;
      }
    }
  }
}
.slick {
  &-list {
    // margin: 0 -10px !important;
  }
  &-track {
    display: flex !important;
  }
  &-slide {
    height: inherit !important;
    & > div {
      height: 100%;
      margin: 0 4px;
      > * {
        height: 100%;
      }
    }
  }
  &-rowslider {
    .slick-slide > div {
      height: unset;
      display: flex;
    }
    padding: 0;
    margin: 0;
  }
  &-dots {
    bottom: -6px !important;
    li {
      width: unset !important;
      height: unset !important;
      margin: 0 0.2rem !important;
      button {
        width: 1.2rem !important;
        height: 0.3rem !important;
        padding: 0 !important;
        background-color: $grey !important;
        border: unset !important;
        border-radius: 4rem;
        &::before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background-color: $dark !important;
          width: 2.2rem !important;
        }
      }
    }
  }
}
#pick_mood , #hero_banner{
  .slick {
    &-dots {
      bottom: -15px !important;
      @media (min-width: 767.98px) {
        bottom: -30px !important;
      }
    }
  }
}


@media (max-width: 767.98px) {
  .slider-gutter-20 {
    .slick-slide > div {
      margin: 0 10px;
    }
  }
}
@media (min-width: 768px) {
  .s {
    &-reward-pool {
      margin-bottom: 60px;
      .s-header {
        padding-top: 20px;
      }
      .slick {
        &-dots {
          bottom: -27px !important;
        }
      }
    }
  }
  .slick {
    // &-slide {
    //   // & > div {
    //   //   margin: 0 15px;
    //   // }
    // }

    &-dots {
      bottom: -3.4rem !important;
    }
  }
}

@media (min-width: 1450px) {
  .slick {
    &-list {
      margin: 0 -15px !important;
    }
  }
}
@media (max-width: 1300.98px) {
  .giggle-carsouel {
    .card {
      &-joke {
        .overlay {
          // min-height: 143px;
          // max-height: 143px;
        }
      }
    }
    .title {
      font-size: 1.2rem;
    }
    .subtitle {
      font-size: 0.9rem;
    }
    .title-icon {
      width: 20px;
    }
    @media (max-width: 420px) {
      .title {
        font-size: 2.6vw;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .subtitle {
        font-size: 3.17vw;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .title-icon {
        width: 6vw;
      }
    }
  }
}
.giggle-carsouel,
.s-reward-pool {
  .slick-list {
    padding: 0 20% 0 0 !important;
  }
}
