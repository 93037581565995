.reward {
  &-logo {
    max-width: 2rem;
    max-height: 2rem;
    width: 100%;
    height: 100%;
    &-wrapper {
      width: 3.2rem;
      height: 3.2rem;
      margin-top: -1.6rem;     //change
      background-color: $danger;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (min-width: 768px) {
 
  .reward {
    &-logo {
      max-width: 3rem;
      max-height: 3rem;
      &-wrapper {
        width: 6.6rem;
        height: 6.6rem;
        margin-top: -6.9rem;
      }
    }
  }
}
@media (min-width:767.98px) {
  .wins-image{
    width: 291px;
  }
  .btn-wins{
      font-size: 2rem;
        padding: 2rem 4.4rem;
    
  }
  
}
