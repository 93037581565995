.bho_global {
  .refer-btns {
    font-size: 2.8vw;
  }
  .offcanvas-menu {
    max-width: 80%;
  }
  .view-more {
    font-size: 1rem;
  }
  .timer-wrapper {
    span{
      font-size: 6px;
      white-space: nowrap;
    }
  }
  .btn {
    &-artist {
      font-size: 8px;
    }
  }
  .leaderboard-banner {
    h2 {
      font-size: 4vw;
    }
    .btn {
      font-size: 2.2vw;
    }
  }
  .s {
    &-title {
      font-size: 1.4rem;
    }
    &-sub-title {
      font-size: 1rem;
    }
    &-comic {
      h6 {
        font-size: 3.1vw;
      }
      h3 {
        font-size: 2.9vw;
      }
    }
  }
  .card {
    &-comedian {
      .card {
        &-text {
          font-size: 7px;
          line-height: 10px;
        }
      }
    }
  }
  @media (min-width: 767.98px) {
    .footer {
      .link {
        font-size: 1.2rem;
      }
    }
    .leaderboard-banner {
      h2 {
        font-size: 2vw;
      }
      .btn {
        font-size: 1.6vw;
      }
    }
    .refer-btns {
      font-size: 1vw;
    }
    .card {
      &-comedian {
        .card {
          &-text {
            font-size: 1vw;
            line-height: 2vw;
          }
        }
      }
    }
    .dropdown-item {
      font-size: 1.4rem !important;
    }
    .timer small {
      font-size: 1vw;
    }
    .view-more {
      font-size: 1.6rem;
    }
    .s {
      &-title {
        font-size: 2.2rem;
      }
      &-sub-title {
        font-size: 1.6rem;
      }
      &-comic {
        h6 {
          font-size: 2.8vw;
        }
        h3 {
          font-size: 2.4vw;
        }
      }
      &-fab {
        h3 {
          font-size: 2.9vw;
        }
        h6 {
          font-size: 2.6vw;
        }
      }
    }
  }
}
