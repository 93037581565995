.kn_global {
  @media (max-width: 374px) {
    .timer-static h4 {
      font-size: 2.67vw;
      white-space: nowrap;
    }
  }
  .refer-btns {
    font-size: 2.34vw;
  }
  .btn {
    &-suprise {
      .text {
        font-size: 7px;
      }
    }
  }
  .sprite-points {
    &-wrapper {
      h3 {
        font-size: 1.6vw;
      }
    }
  }

  .offcanvas-menu {
    max-width: 78%;
    .wp-wrapper {
      span {
        font-size: 3vw;
      }
    }
  }

  .leaderboard-banner {
    .content-wrapper {
      .btn {
        font-size: 8px;
      }
      h2 {
        font-size: 2.95vw;
      }
    }
  }

  .btn {
    &-artist {
      padding: 0.8rem 1.6rem;
      font-size: 0.7rem;
    }
  }
  .timer strong {
    font-size: 0.8rem;
  }
  .timer small {
    font-size: 0.6rem;
  }
  .sprite-points {
    .s-title {
      font-size: 3.2vw;
      white-space: nowrap;
    }
  }
  .s {
    &-sub-title {
      font-size: 2.2vw;
    }

    &-header {
      .view-more {
        font-size: 2.2vw;
      }
    }
    &-laugh {
      .btn {
        font-size: 8px;
        padding: 0.8rem 1.6rem;
      }
    }
    &-fab {
      h3 {
        font-size: 1.96vw;
      }
    }

    &-comic {
      h3 {
        font-size: 2.4vw;
      }
      .btn {
        font-size: 1rem;
      }
    }
    &-comingsoon {
      h3 {
        font-size: 2.18vw;
      }
    }
  }
  .card {
    &-comedian {
      .card {
        &-text {
          font-size: 7px;
        }
      }
    }
  }
  @media (min-width: 767.98px) {
    .contest {
      &-banner {
        .contest {
          &-para {
            font-size: 1.4rem;
          }
        }
      }
    }
    .refer-btns {
      font-size: 1.134vw;
    }
    .btn {
      &-suprise {
        .text {
          font-size: 1.1rem;
        }
      }
    }
    .sprite-points {
      .s-title {
        font-size: 2vw;
      }
      &-wrapper {
        h3 {
          font-size: 1vw;
        }
      }
    }
    .btn {
      &-artist {
        padding: 1.2rem 3.6rem;
        font-size: 1.6rem;
      }
    }
    .leaderboard-banner {
      .content-wrapper {
        .btn {
          font-size: 1.6rem;
        }
        h2 {
          font-size: 2vw;
        }
      }
    }
    .s {
      &-font {
        img {
          width: 27px !important;
          height: 28px;
        }
      }
      &-comingsoon {
        h3 {
          font-size: 1.76vw;
        }
      }
      &-header {
        .view-more {
          font-size: 2rem;
        }
      }

      &-sub-title {
        font-size: 2rem;
      }
      &-comic {
        h3 {
          font-size: 2.1vw;
        }
        .btn {
          font-size: 2rem;
        }
      }
    }
    .card {
      &-comedian {
        h4 {
          font-size: 0.8vw;
        }
        .card {
          &-text {
            font-size: 0.7vw;
          }
        }
        @media (max-width: 1400px) and (min-width: 575px) {
          h4 {
            min-height: 34px;
            font-size: 12px;
            margin-bottom: 0 !important;
          }
          .card {
            &-text {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .timer strong {
      font-size: 3.9rem;
    }
    .timer small {
      font-size: 2.6rem;
    }
  }
  @media (max-width: 767px) {
    .contest {
      &-bottle {
        height: 65px;
        margin-left: 30px;
        position: relative;
        top: 12%;
      }
    }
  }
}
