.ba_global {
  @media (max-width: 370px) {
    .sprite-points {
      &-wrapper {
        h3 {
          font-size: 7px;
        }
      }
    }
  }
  .modal {
    &-title {
      font-size: 1.2rem;
    }
    .btn-link {
      font-size: 1rem;
    }
  }
  .s {
    &-comingsoon {
      h3 {
        font-size: 2.934vw;
      }
    }
    &-comic {
      h6 {
      }
      .btn {
        font-size: 0.8rem;
        margin-top: 5px;
      }
    }
    // &-shareJokes {
    //   h6 {
    //     font-size: 3.42vw;
    //   }
    // }
    &-fab {
      h3 {
        font-size: 2.298vw;
      }
    }
    &-laugh {
      .btn {
        font-size: 2vw;
      }
    }
    &-sub-title {
      font-size: 8px;
    }
    &-header {
      .view-more {
        font-size: 8px;
      }
    }
  }
  .wp-wrapper {
    span {
      font-size: 2.85vw;
    }
    .btn {
      font-size: 2.6vw;
      padding: 5px 10px;
    }
  }
  @media (min-width: 767.98px) {
    .contest {
      &-banner {
        .contest {
          &-para {
            font-size: 1.4rem;
          }
        }
      }
    }
    .modal {
      &-title {
        font-size: 1.6rem;
      }
    }
    .s {
      // &-shareJokes {
      //   h6 {
      //     font-size: 3.68vw;
      //     margin-bottom: 10px;
      //   }
      // }
      &-comingsoon {
        h3 {
          font-size: 2.4vw;
          line-height: 3vw;
        }
      }
      &-comic {
        .btn {
          font-size: 1.15vw;
          margin-top: 25px;
        }
      }
      &-laugh {
        .btn {
          font-size: 1.5vw;
          padding: 1vw 4.8vw;
        }
      }
      &-fab {
        h3 {
          font-size: 1.98vw;
        }
      }
      &-sub-title {
        font-size: 1.6rem;
      }
      &-header {
        .view-more {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .contest {
      &-bottle {
        height: 65px;
        margin-left: 30px;
        position: relative;
        top: 12%;
      }
    }
  }
}
