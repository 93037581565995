.reactour__helper.reactour__helper--is-open {
  border-radius: 200px;
  background: #ffe200;
  width: 185px;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tour-heading {
  font-size: 1.6rem;
  text-align: left;
}
.reactour__helper--is-open:has(.change_language) {
  transform: none;
  right: -19px !important;
  left: auto !important;
  top: -51px;
  z-index: 99999;
  padding-left: 14px;
  width: 216px;
  height: 216px;
  img {
    float: right;
  }
}

// Desktop change language
.reactour__helper--is-open:has(.change-lang-d) {
  transform: none;
  right: 145px;
  left: auto !important;
  top: -82px;
  z-index: 99999;
  padding-left: 14px;
  width: 290px;
  height: 257px;

  img {
    float: right;
  }
}
// Desktop Explore
.reactour__helper--is-open:has(.tour-explore) {
  transform: none;
  left: 305px !important;
  width: 282px;
  height: 269px;
  top: -94px;

  img {
    float: right;
  }
}
.reactour__helper--is-open:has(.tour-contest) {
  transform: none;
  left: 450px !important;
  width: 282px;
  height: 269px;
  top: -94px;
}

// Desktop Avatar profile
.reactour__helper--is-open:has(.avatar_icon) {
  transform: none;
  right: 94px;
  left: auto !important;
  top: -86px;
  z-index: 99999;
  padding-left: 14px;
  width: 272px;
  height: 272px;
  .avatar_icon {
    img {
      float: right;
    }
  }
}
.reactour__helper--is-open:has(.tour-pick) {
  border-radius: 200px;
  background: #ffe200;
  width: 160px;
  transform: none;
  height: 160px;
  left: -26px;
  top: 212px;
  @media (min-width: 991px) {
    height: 230px;
    width: 230px;
    top: 420px;
  }
}
.tour-pick {
  width: 100px;
  @media (min-width: 992px) {
    width: 150px;
  }
}
.reactour__helper--is-open:has(.tour-comedian) {
  border-radius: 200px;
  background: #ffe200;
  width: 144px;
  transform: none;
  height: 151px;
  left: -26px;
  top: 450px;
  @media (min-width: 991px) {
    height: 230px;
    width: 240px;
    top: 300px;
  }
}
.tour-comedian {
  width: 80px;
  @media (min-width: 991px) {
    width: 150px;
  }
}
.reactour__helper--is-open:has(.tour-tab) {
  border-radius: 200px;
  background: #ffe200;
  width: 144px;
  transform: none;
  height: 151px;
  left: -26px;
  top: 240px;
  @media (min-width: 991px) {
    border-radius: 200px;
    background: #ffe200;
    width: 200px;
    transform: none;
    height: 219px;
    left: -26px;
    top: 315px;
  }
}
.tour-tab {
  width: 80px;
  @media (min-width: 991px) {
    width: 120px;
  }
}
.reactour__helper--is-open:has(.home) {
  transform: none;
  left: -40px !important;
  bottom: -31px;
  top: unset !important;
  z-index: 99999;
  padding-left: 37px;
  width: 163px;
  height: 163px;
  .home {
    display: flex;
    flex-direction: column-reverse;
  }
  img {
    width: 50px;
  }
}
.reactour__helper--is-open:has(.contest) {
  transform: none;
  left: 45px !important;
  bottom: -31px;
  top: unset !important;
  z-index: 99999;
  padding-left: 37px;
  padding-right: 42px;
  width: 185px;
  height: 175px;

  .contest {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .tour-text {
    text-align: center !important;
  }
  img {
    width: 50px;
  }
}

.reactour__helper--is-open:has(.wallet) {
  transform: none;
  left: 166px !important;
  bottom: -28px;
  top: unset !important;
  z-index: 99999;
  padding-left: 37px;
  padding-right: 42px;
  width: 175px;
  height: 175px;

  .wallet {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .tour-text {
    text-align: center !important;
  }
  img {
    width: 87px;
    height: 87px;
  }
}

.reactour__helper--is-open:has(.unique-code) {
    transform: none;
    left: 105px !important;
    bottom: -39px;
    top: unset !important;
    z-index: 99999;
    padding-left: 13px;
    padding-right: 20px;
    width: 188px;
    height: 188px;

  .unique-code {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .tour-text {
    text-align: center !important;
  }
  img {
    width: 54px;
    height: 54px;
  }
}
.button.reactour__dot--is-active,
button.reactour__dot {
  display: none !important;
}
.reactour__helper--is-open:has(.profile-tour) {
  transform: none;
    left: unset !important;
    right: -27px !important;
    bottom: -23px;
    top: unset !important;
    z-index: 99999;
    padding-left: 31px;
    width: 155px;
    height: 155px;

  .profile-tour {
    display: flex;
    flex-direction: column-reverse;
  }

  img {
    width: 50px;
  }
}
.reactour__helper--is-open:has(.navbar-toggler) {
  transform: none;
  left: -28px !important;
  top: -25px;
  padding-left: 45px;
  z-index: 99999;
}

.ghknmB {
  display: none !important;
}
.tour-text {
  color: #000;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tour-btn-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  div {
    margin-right: 1.12rem;
  }
  .tour-btn-link {
    color: $link-color;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
@media (min-width: 767.98px) {
  // .reactour__helper--is-open:has(.tour-comedian) {
  //   border-radius: 200px;
  //   transform: none;
  //   left: -28px !important;
  //   width: 189px;
  //   height: 211px;
  //   top: 307px;
  //   text-align: left;
  // }

  .tour {
    &-heading {
      font-size: 2.2rem;
      text-align: left;
    }
    &-text {
      font-size: 1.8rem;
      text-align: left;
    }
  }
}
.left-wrapper {
  width: 1px !important;
  @media (min-width: 991px) {
    position: relative;
    left: -250px;
  }
}
