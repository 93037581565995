.footer {
  padding: 7px 0 0 0px;
  position: fixed;
  left: 0;
  z-index: 2;
  right: 0;
  z-index: 1;
  bottom: 0;
  background-color: $light;
  &-reaction {
    img {
      width: 1.2rem;
      height: 1.2rem;
    }

    .icon-num {
      font-size: 1rem;
      margin-top: 3px;
      font-family: "Aktiv Grotesk" !important;
    }
  }
  &-links {
    background: #e0e0e0;
    color: $dark;
    .bootom-link {
      color: $dark;
      font-size: 1.7vw;
    }
  }
  .count {
    color: $light;
    background: #717171;
  }
  .link {
    color: $dark;
    text-decoration: none;
    white-space: nowrap;
    line-height: 2;
    margin-top: 2px;
    font-size: 9px;
    text-transform: uppercase;
    margin-bottom: -10px;
    border-bottom: 2px solid transparent;
    &.unique {
      &-code {
        text-align: center;
        background: #ffe200;
        border-radius: 100px;
        border: 3px solid $light;
        margin-top: -48px;
        padding: 5px 16px;
        img {
          width: 21px;
          height: 30px;
        }
        span {
          font-size: 8px;
          line-height: 10px;
        }
      }
    }
    &:hover,
    &.active {
      .border-footer {
        border: 2px solid #009639;
        width: 100%;
        border-radius: 4px 4px 0px 0px;
      }
      span {
        color: $primary;
        font-size: 9px;
        margin-top: 2px;
        text-transform: uppercase;
        transition: filter 0.3s ease-in-out;

        .count {
          background-color: $light;
          color: $dark;
        }
      }
      .footer {
        &-icon {
          filter: brightness(0) saturate(100%) invert(27%) sepia(99%)
            saturate(1653%) hue-rotate(131deg) brightness(98%) contrast(104%);
        }
      }
    }
  }
  .dietary-icon {
    width: 5px;
    height: 5px;
    vertical-align: top;
    margin-left: 0.5rem;
  }
  p {
    font-size: 1.7vw;
    color: $dark;
  }
  .text-link {
    color: #9b9b9b;
    &:hover {
      color: #9b9b9b;
      text-decoration: none;
    }
  }
  &-claim-form {
    position: fixed;
    bottom: 27px;
    width: 95%;

    .link,
    p {
      color: $dark;
    }
    .link {
      font-size: 2.51vw;
      white-space: nowrap;
    }
    p {
      font-size: 1.34vw;
    }
    @media (min-width: 767.98px) {
      .link {
        font-size: 1.2rem;
      }

      p {
        font-size: 7px;
      }
    }
  }

  @media (min-width: 992px) {
    background-color: $dark;
    padding: 3.3rem 15rem;
    position: static;
    .text-link {
      color: $light;
      font-weight: 700;
      font-size: 1.6rem;
    }
    &-subheading {
      display: block;
    }
    &-reaction {
      img {
        width: 30px !important;
        height: 30px !important;
      }
      .icon-num {
        font-size: 1.2rem;
      }
    }
    .link {
      color: #f2f2f2;
      font-size: 1.6rem;
      text-transform: none;
    }
    .dietary-icon {
      width: 15px;
      height: 15px;
    }
    .logo {
      width: 110px;
    }
    li {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.4rem;
    }
  }
}
.count {
  font-size: 7px;
  font-weight: bold;
  line-height: 10px;
  color: $dark;
  background-color: $light;
  border-radius: 8px;
  padding: 0 3px;
  position: absolute;
  top: -4px;
  left: 50%;
  min-width: 2rem;
  text-align: center;
  transform: translateX(-20%);
}

// Progress scss
.progress {
  &-wrapper {
    padding: 2rem 0;
    //background-color: yellow;
    position: relative;
  }
  &-bar {
    width: 91px;
    height: 0.7rem;
    background-color: #cdcdcd;
    position: absolute;
    left: 5px;

    right: 0;
    top: 5.3rem;
  }
  &-percentage {
    height: 100%;
    width: 10%;
    background-color: $danger;
  }
  &-bullet {
    display: flex;
    justify-content: space-between;
    list-style: none;
    &-list {
      position: relative;
      &:first-child,
      &:last-child {
        .progress-points {
          background-color: transparent;
          color: $dark;
          &::after {
            display: none;
          }
        }
      }
    }
  }
  &-circle {
    background-color: $light;
    width: 1.8rem;
    height: 1.8rem;
    border: 0.5rem solid $grey-2;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    left: 97%;
    top: calc(100% + 1rem);
    transform: translateX(-50%);
    &.active {
      border-color: $danger;
    }
  }
  &-prize {
    display: inline-block;
    position: absolute;
    // right: 30%;
    right: 0%;
    top: calc(100% + 3rem);
    // transform: translateX(-50%);
    &.active {
      color: $danger;
    }
  }
  &-points {
    background-color: $dark;
    padding: 0.4rem;
    font-size: 1.5rem;
    line-height: 1;
    color: $light;
    text-align: center;
    border-radius: 4px;
    position: relative;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $dark;
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
    }
  }
}

// .range-3000{
//   left:100%;
// }

.range-0 {
  left: 70%;
}
.range-500 {
  left: 177%;
}
.range-prize-500 {
  right: -85%;
}

.range-300 {
  left: 140%;
}

.coin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(0);
  z-index: 999999;
  background-color: $danger;
  width: 300px;
  height: 300px;
}

@keyframes zoomInOut {
  0% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
  }
}

.moveCoin {
  animation: zoomInOut 1.5s forwards; /* Removed 'transform 1s forwards' */
}

.range-1000 {
  left: 95%;
}
.range-prize-1000 {
  right: 5%;
}
.range-prize-300 {
  left: -30%;
}

.range-points-100 {
  left: 45%;
}
.range-points-300 {
  left: 90%;
}
.range-points-500 {
  left: 125%;
}

@media (max-width: 560px) {
  .progress-prize {
    font-size: 1rem;
  }
}
